import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Stepper from "react-stepper-horizontal";
import Session from "../Utilities/Session";
import {getSteps} from "../Utilities/StepUtilities";

const steps = getSteps();
const UpdateInformationPage = () => {
    const [currentStep, setCurrentStep] = React.useState(Session.getInstance().getLastStep());
    const setStep = (i: number) => {
        if (i >= 0 && i < steps.length) {
            setCurrentStep(i);
            Session.getInstance().setLastStep(i);
        }
    }

    const handleSetStep = (incrementAmount: number) => setStep(currentStep + incrementAmount);

    const handleStepperClick = (step: number) => (sender: React.MouseEvent<HTMLAnchorElement>) => {
        sender.preventDefault();
        sender.stopPropagation();
        setStep(step);
    }

    return (
        <div>
            <Col className="d-none d-sm-block">
                <Stepper
                    steps={steps.map((s, i) => ({...s, onClick: handleStepperClick(i)}))}
                    activeStep={currentStep}
                    activeColor="#0A3069"
                    circleFontSize={0}
                    defaultColor="#F8BD28"
                    defaultOpacity="30%"
                    defaultTitleOpacity="40%"
                />
            </Col>
            <Container fluid>
                {React.createElement(steps[currentStep].component, {onButtonClick: handleSetStep})}
            </Container>
        </div>
    );
}
export default UpdateInformationPage;