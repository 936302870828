import React from "react";
import {BaseAddress} from "../Utilities/Models";
import Modal from "react-bootstrap/cjs/Modal";
import Spinner from "react-bootstrap/cjs/Spinner";
import Button from "react-bootstrap/Button";
import {Form} from "react-bootstrap";
import Utilities, {CancelablePromise} from "../Utilities/Utilities";

interface GeocoderModalProps {
    lookupAddress?: BaseAddress;
    onSelectAddress: (address: BaseAddress) => void;
}
const GeocoderModal: React.FunctionComponent<GeocoderModalProps> = ({lookupAddress, onSelectAddress}) => {
    const [addressNotFound, setAddressNotFound] = React.useState(false);
    const [geocodedAddresses, setGeocodedAddresses] = React.useState<BaseAddress[]>();
    const [selectedAddress, setSelectedAddress] = React.useState<BaseAddress>();
    const handleClose = () => {
        setSelectedAddress(undefined);
        setAddressNotFound(false);
    }

    React.useEffect(() => {
        let request: CancelablePromise<BaseAddress[]> | undefined = undefined;
        if (lookupAddress) {
            request = Utilities.fetchJSON<BaseAddress[]>({
                url: '/geocode',
                body: JSON.stringify(lookupAddress)
            });
            request.promise.then((addresses: BaseAddress[]) => {
                if (addresses && addresses.length > 0) {
                    setGeocodedAddresses(addresses);
                }
                else {
                    setAddressNotFound(true);
                }
            }).catch(error => {
                if (!error.isCanceled) {
                    setAddressNotFound(true);
                }
            });
        }
        else {
            setGeocodedAddresses(undefined);
        }
        return () => {
            request?.cancel();
        }
    }, [lookupAddress, onSelectAddress]);

    return (
        <Modal
            show={lookupAddress !== undefined}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>Please select an address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(() => {
                    if (addressNotFound) {
                        return "Unable to lookup address.";
                    }
                    else if (geocodedAddresses) {
                        return (
                            <AddressPicker
                                addresses={geocodedAddresses}
                                onSelect={setSelectedAddress}
                            />
                        );
                    }
                    else {
                        return (
                            <Spinner
                                animation="border"
                                role="status"
                            />
                        );
                    }
                })()}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        onSelectAddress({
                            latitude: undefined,
                            longitude: undefined
                        });
                        handleClose();
                    }}
                >Re-Enter Address</Button>
                <Button
                    onClick={() => {
                        if (selectedAddress) {
                            onSelectAddress(selectedAddress);
                        }
                        else if (addressNotFound) {
                            onSelectAddress({latitude: 0, longitude: 0});
                        }
                        handleClose();
                    }}
                    disabled={selectedAddress === undefined && !addressNotFound}
                >{addressNotFound ? "Keep Current Address" : "Select Address"}</Button>
            </Modal.Footer>
        </Modal>
    );
}


interface AddressPickerProps {
    addresses: BaseAddress[];
    onSelect: (address: BaseAddress) => void;
}

const AddressPicker = (props: AddressPickerProps) => {
    return (
        <Form>
            {props.addresses.map((a, i) => {
                let label = a.street;
                if (a.street2) {
                    label += `, ${a.street2}`
                }
                label += `, ${Utilities.displayCity(a)}`;
                label += `, ${a.state?.name} ${a.zip}`;
                return (
                    <Form.Check
                        type='radio'
                        label={label}
                        id={label}
                        key={i}
                        onClick={() => props.onSelect(a)}
                    />
                );
            })}
        </Form>
    );
}
export default GeocoderModal;
