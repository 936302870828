import React from "react";
import {UseIdDropDownProps, UseValueDropDownProps} from "./DropDownInput";
import {LookupValue} from "../generated";
import CategoryValuePicker from "./CategoryValuePicker";

type LookupValuePickerProps = (UseIdDropDownProps<LookupValue> | UseValueDropDownProps<LookupValue>) & {
    type: "County" | "City" | "Phone Type" | "Suffix" | "Employment Type" | "Parking Locations";
}

interface LookupValueState {
}
class LookupValuePicker extends React.Component<LookupValuePickerProps, LookupValueState> {
    static defaultProps = {
        displayKey: "value",
        nullValue: "---",
        options: []
    }

    render() {
        return (
            <CategoryValuePicker<LookupValue>
                {...this.props}
                apiUrl={`/lookup?lookupType=${this.props.type}`}
            />
        );
    }
}

export default LookupValuePicker;