import React, {Component} from "react";
import {ManagedInput} from "./ManagedInput";
import InputLabelAndFeedback, {InputLabelAndFeedbackProps} from "./InputLabelAndFeedback";

interface InputFormatPiece {
    length: number;
    format: (value: string) => string;
}

interface NumberInputProps extends InputLabelAndFeedbackProps {
    placeholder?: string;
    value?: string;
    minLength?: number;
    maxLength: number;
    onChange(value: string): void;
    regexp: RegExp;
    type?: 'phone' | 'number';
    id: string;
}

const PhoneNormalizers: InputFormatPiece[] = [
    {
        length: 2,
        format: value => value
    },{
        length: 3,
        format: value => `${value}-`
    },{
        length: 5,
        format: value => {
            return `${value.slice(0, 3)}-${value.slice(3)}`;
        }
    },{
        length: 6,
        format: value => {
            return `${value.slice(0, 3)}-${value.slice(3)}-`;
        }
    },{
        length: 10,
        format: value => {
            return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
        }
    }
];

const normalizeInput = (value: string, previousValue:string, normalizers: InputFormatPiece[]):string => {
    //Always allow deletion without formatting (copy paste doesn't seem to work)
    if (value.length < previousValue.length) {
        return value;
    }

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    for (let normalizer of normalizers) {
        if (cvLength <= normalizer.length) {
            return normalizer.format(currentValue);
        }
    }
    return normalizers[normalizers.length - 1].format(currentValue);
};

export default class NumberInput extends Component<NumberInputProps> {
    static defaultProps = {
        regexp: /^[0-9-]+$/,
        type: 'number'
    }
    constructor(props: NumberInputProps) {
        super(props);
        if (props.type === 'phone') {
            this.normalizers = PhoneNormalizers;
        }
    }
    ref = React.createRef<HTMLInputElement>();
    normalizers?: InputFormatPiece[];
    onChange = (value: string) => {
        if (this.normalizers) {
            const normalValue = normalizeInput(value, this.props.value ?? "", this.normalizers);
            if (normalValue !== this.props.value) {
                this.props.onChange(normalValue);
            }
        }
        else if (value !== this.props.value) {
            this.props.onChange(value);
        }
    }

    componentDidUpdate(prevProps: Readonly<NumberInputProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.props.value !== prevProps.value && this.normalizers && this.props.minLength && this.ref?.current) {
            if (!this.props.value || this.props.value.length < this.props.minLength) {
                this.ref.current.setCustomValidity("invalid");
            }
            else {
                this.ref.current.setCustomValidity("");
            }
        }
    }

    render() {
        return (
            <InputLabelAndFeedback
                prompt={this.props.prompt}
                colProps={this.props.colProps}
                errormessage={this.props.errormessage}
                id={this.props.id}
            >
                <ManagedInput
                    regexp={this.props.regexp}
                    onChange={this.onChange}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    maxLength={this.props.maxLength}
                    minLength={this.props.minLength}
                    required={this.props.errormessage !== undefined}
                    inputRef={this.ref}
                    labelledby={this.props.id}
                    type={this.props.type}
                />
            </InputLabelAndFeedback>
        );
    }
}