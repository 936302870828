import React from "react";
import {SectionNextButtonHandler} from "./SectionBase";
import Form from "react-bootstrap/Form";
import StatePicker from "../Inputs/StatePicker";
import TextInput from "../Inputs/TextInput";
import {EyeColor, OffenderVehicle, Vehicle} from "../generated";
import ListSectionBase, {ListSectionStringsFromItemName} from "./ListSectionBase";
import VehicleDisplay from "../DisplayOnly/VehicleDisplay";
import DropDownInput from "../Inputs/DropDownInput";
import ColorPicker from "../Inputs/ColorPicker";
import VehicleModelInput from "../Inputs/VehicleModelInput";
import YesNoInput from "../Inputs/YesNoInput";
import LookupValuePicker from "../Inputs/LookupValuePicker";

type Color = EyeColor;
interface VehicleYear {
    id: number;
    value: string;
}
const VehicleYears: VehicleYear[] = (function (){
    let years = [] as VehicleYear [];
    const startYear = (new Date()).getFullYear() + 1;
    const endYear = 1904;
    for (let year = startYear; year > endYear; year--) {
        const value = `${year}`;
        years.push({id: year, value});
    }
    return years;
})();

const VehicleSection: React.FunctionComponent<SectionNextButtonHandler> = ({onButtonClick}) => {

    const displayVehicleInput = (vehicle: Vehicle, updateItem: (vehicle: Partial<Vehicle>) => void):JSX.Element => {
        return (
            <Form.Group>
                <Form.Row className="align-items-center">
                    <TextInput
                        prompt="Vehicle License Plate"
                        placeholder="Plate #"
                        value={vehicle.licensePlate}
                        onChange={licensePlate => updateItem({licensePlate: licensePlate.toUpperCase()})}
                        id="plate"
                        errormessage="You must enter a license plate."
                        maxLength={50}
                    />
                    <StatePicker
                        value={vehicle.state}
                        onChange={state => updateItem({state})}
                        errormessage="You must select a state."
                        useId
                    />
                </Form.Row>
                <VehicleModelInput
                    onChange={updateItem}
                    vehicleType={vehicle.vehicleType}
                    vehicleMake={vehicle.vehicleMake}
                    vehicleModel={vehicle.vehicleModel}
                />
                <Form.Row>
                    <DropDownInput<VehicleYear>
                        colProps={{sm: 3}}
                        prompt="Vehicle Year"
                        nullValue="YYYY"
                        value={vehicle.year}
                        displayKey='value'
                        onChange={(year?: string) => updateItem({year})}
                        id="year"
                        options={VehicleYears}
                    />
                    <ColorPicker
                        apiUrl="/lookup/vehicle/color"
                        colProps={{sm: 3}}
                        prompt="Color"
                        value={vehicle.color as Color}
                        onChange={color => updateItem({color})}
                        id="color"
                        errormessage="You must select a vehicle color."
                        useId
                    />
                    <TextInput
                        colProps={{sm: 6}}
                        prompt="VIN"
                        placeholder="#######"
                        value={vehicle.vin}
                        onChange={vin => updateItem({vin})}
                        id="vin"
                        maxLength={50}
                    />
                    <LookupValuePicker
                        value={vehicle.parkingLocation}
                        useId
                        onChange={parkingLocation => updateItem({parkingLocation})}
                        id='parkingLocation'
                        prompt='Where do you generally park this vehicle?'
                        type='Parking Locations'
                    />
                    {vehicle.parkingLocation?.value === "Other" &&
                        <TextInput
                            colProps={{ xs: 12 }}
                            prompt='Other parking location'
                            value={vehicle.otherParkingLocation}
                            onChange={otherParkingLocation => updateItem({otherParkingLocation})}
                            id='otherParkingLocation'
                            maxLength={350}
                            required
                        />
                    }
                </Form.Row>
                <YesNoInput
                    question='Do you own this vehicle?'
                    onChange={owns => updateItem({owns})}
                    id='owns'
                    feedback='You must answer this question.'
                    value={vehicle.owns}
                    questionColProps={{md: 6}}
                />
                <YesNoInput
                    question='Do you use this vehicle?'
                    onChange={uses => updateItem({uses})}
                    id='uses'
                    feedback='You must answer this question.'
                    value={vehicle.uses}
                    questionColProps={{md: 6}}
                />
            </Form.Group>
        );
    };

    return (
        <ListSectionBase<Vehicle, OffenderVehicle>
            strings={ListSectionStringsFromItemName("Vehicle")}
            apiUrl='/vehicle'
            onItemSubmit={onButtonClick}
            displayItemInput={displayVehicleInput}
            displayItem={(vehicle) => <VehicleDisplay {...vehicle}/>}
            onOldItemCurrentChange={(current, updateItem) => updateItem({current})}
            >
        </ListSectionBase>
    );
}

export default VehicleSection;