import React from "react";
import Utilities from "../Utilities/Utilities";
import Row from "react-bootstrap/Row";
import {SectionNextButtonHandler} from "./SectionBase";
import NamesDetailInput from "../Inputs/NamesDetailInput";
import AddOnlyListSection from "./AddOnlyListSection";
import {Alias} from "../generated";


const AliasesSection: React.FunctionComponent<SectionNextButtonHandler> = ({onButtonClick}) => {
    return (
        <AddOnlyListSection<Alias>
            url='/alias'
            prompt="Add Additional Aliases"
            readonlyHeader="Current Aliases"
            addNewItemPrompt="Add Alias"
            displayReadonlyItem={alias => <Row>{Utilities.parseName(alias)}</Row>}
            displayItem={(item, updateItem) => <NamesDetailInput {...item} updateItem={updateItem}/>}
            onButtonClick={onButtonClick}
        />
    );
}

export default AliasesSection;