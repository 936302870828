import React from "react";
import Form from "react-bootstrap/Form";
import StatePicker from "./StatePicker";
import TextInput from "./TextInput";
import {BaseAddress} from "../Utilities/Models";
import LookupValuePicker from "./LookupValuePicker";
import Geocoder from "../Geocoder/Geocoder";
import CityInput from "./CityInput";
import {USState} from "../generated";
import {ManagedInput} from "./ManagedInput";
import Col from "react-bootstrap/cjs/Col";
import Utilities from "../Utilities/Utilities";
import CategoryValuePicker from "./CategoryValuePicker";
import {
    isCountyOutOfCountry, isCountyOutOfState,
    isStateOutOfCountry,
    isStateWisconsin,
    isUnitedStates,
    UnitedStates, Wisconsin
} from "../Utilities/Locations";

interface AddressInputProps {
    title?: string;
    address: BaseAddress;
    onChange: (address: BaseAddress) => void;
    requireInputs?: boolean;
    noCounty?: boolean;
    noGeocoder?: boolean;
}

const AddressInput: React.FunctionComponent<AddressInputProps> = (
    {
        title = "Street Address",
        requireInputs = true,
        onChange, noCounty, noGeocoder, ...props
    }) => {

    const handleUpdateState = React.useCallback((state?: USState) =>
        onChange({...props.address, state}), [onChange, props.address]);

    React.useEffect(() => {
        if (!props.address.state) {
            handleUpdateState(Wisconsin);
        }
    }, [props.address.state, handleUpdateState]);

    const isWisconsin = isStateWisconsin(props.address.state);
    const stateOutOfCountry = isStateOutOfCountry(props.address.state);
    const countyOfCountry = !noCounty && isCountyOutOfCountry(props.address.county);
    const outOfCountry = stateOutOfCountry || countyOfCountry;

    React.useEffect(() => {
        if (!outOfCountry && !isUnitedStates(props.address.country)) {
            onChange({...props.address, country: UnitedStates})
        }
    }, [outOfCountry, onChange, props.address]);
    const errorMessages: Record<string, string> = requireInputs ? {
        'street': "You must enter a street address.",
        'state': "You must select a state.",
        'zip': "You must enter a zip code.",
        'county': "You must select a county.",
        'country': "You must select a country."
    } : {};

    return (
        <fieldset disabled={Utilities.addressIsEditable(props.address)}>
            <Form.Row>
                <TextInput
                    prompt={title}
                    value={props.address.street}
                    onChange={street => onChange({...props.address, street})}
                    errormessage={errorMessages['street']}
                    id="street"
                    placeholder='Street'
                    maxLength={100}
                />
            </Form.Row>
            <Form.Row>
                <Col>
                    <ManagedInput
                        placeholder='Street line 2 (optional)'
                        value={props.address.street2}
                        onChange={street2 => onChange({...props.address, street2})}
                        labelledby="street2"
                        maxLength={100}
                    />
                </Col>
            </Form.Row>
            <Form.Row>
                <CityInput
                    city={props.address.city}
                    outOfStateCity={props.address.outOfStateCity}
                    onChange={(city, outOfStateCity) =>
                        onChange({
                            ...props.address,
                            city,
                            outOfStateCity
                        })}
                    inWisconsin={isWisconsin}
                    requireInputs={requireInputs}
                />
                <StatePicker
                    value={props.address.state}
                    onChange={handleUpdateState}
                    errormessage={errorMessages['state']}
                    useId={true}
                    isInvalid={outOfCountry !== stateOutOfCountry}
                />
                <TextInput
                    colProps={{sm: 4}}
                    prompt="Zip Code"
                    placeholder="#####"
                    value={props.address.zip}
                    onChange={zip => onChange({...props.address, zip})}
                    minLength={outOfCountry ? 1 : 5}
                    maxLength={10}
                    regexp={outOfCountry ? /^.+/: /(^\d{5}$)|(^\d{5}-\d{4}$)/}
                    id="zip"
                    variant={"zip"}
                    errormessage={errorMessages['zip']}
                    type={"text"}

                />
                {!noCounty &&
                <LookupValuePicker
                    colProps={{sm: 4, md: 5, lg: 4}}
                    value={props.address.county}
                    onChange={county => onChange({...props.address, county})}
                    type='County'
                    id='county'
                    prompt='County'
                    errormessage={errorMessages['county']}
                    useId
                    isInvalid={outOfCountry !== countyOfCountry || (!outOfCountry && isWisconsin === isCountyOutOfState(props.address.county))}
                />}
                <CategoryValuePicker
                    colProps={{sm: 4, md: 5, lg: 4}}
                    value={props.address.country}
                    onChange={country => onChange({...props.address, country})}
                    apiUrl='/lookup/countries'
                    id='country'
                    prompt='Country'
                    errormessage={errorMessages['country']}
                    displayKey='description'
                    useId
                    defaultValue={UnitedStates}
                    hidden={!outOfCountry}
                    isInvalid={outOfCountry && isUnitedStates(props.address.country)}
                />
                {!noGeocoder && !outOfCountry && <Geocoder address={props.address} onUpdate={onChange}/>}
            </Form.Row>
        </fieldset>
    );
}
export default AddressInput;