import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {OffenderVehicle, Vehicle} from "../generated";

 const VehicleDisplay: React.FunctionComponent<Vehicle | OffenderVehicle> = (
     {
         licensePlate,
         state,
         vehicleMake,
         vehicleModel,
         color,
         vin,
         current,
         year
     }) => {
     return (
         <div style={current ? undefined : {textDecoration: 'line-through'}}>
             <Row>
                 <Col md={6}>Vehicle License Plate #: {licensePlate}</Col>
                 <Col>Issuing State: {state?.name}</Col>
             </Row>
             <Row>
                 <Col md={6}>Make: {vehicleMake?.description}</Col>
                 <Col>Model: {vehicleModel?.description}</Col>
             </Row>
             <Row>
                 <Col md={6}>Color: {color?.description}</Col>
                 <Col>Vin #: {vin}</Col>
             </Row>
             <Row>
                 <Col md={6}>Year: {year}</Col>
             </Row>
         </div>
     );
}
export default VehicleDisplay;