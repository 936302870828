import React from "react";
import Utilities, {CancelablePromise} from "../Utilities/Utilities";
import { Form } from "react-bootstrap";
import DropDownInput, {UseIdDropDownProps, UseValueDropDownProps} from "./DropDownInput";
import {EyeColor} from "../generated";

type Color = EyeColor;

type ColorPickerProps = {
    apiUrl: string;
} & (UseIdDropDownProps<Color> | UseValueDropDownProps<Color>);
interface ColorPickerState {
    colors: Color[];
    error: any | null;
}

export default class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
    static defaultProps = {
        displayKey: "description",
        options: [],
        useId: false,
        nullValue: '---'
    }
    state = {
            colors: [] as Color[],
            error: null
        }
    request?: CancelablePromise<any> = undefined;
    componentWillUnmount() {
        this.request?.cancel();
    }
    componentDidMount() {
        this.request = Utilities.fetchJSON<Color[]>({url: this.props.apiUrl, useCache: true});
        this.request.promise
            .then(colors => {
                this.setState({colors});
                this.request = undefined;
            })
            .catch(error => {
                if (!error.isCanceled) {
                    this.setState({error});
                    this.request = undefined;
                }
            });
    }

    render() {
        if (this.state.error) {
            return (
                <Form.Label>Error loading colors: {`${this.state.error}`}</Form.Label>
            );
        }
        const { apiUrl, ...dropDownProps} = this.props;
        return (
            <DropDownInput<Color>
                {...dropDownProps}
                options={this.state.colors}
            />
        );
    }
}