import React, {ChangeEvent, Component, RefObject} from "react";
import Form from "react-bootstrap/Form";
import {FormControlProps} from "react-bootstrap";

export interface ManagedInputProps extends Omit<FormControlProps, 'onChange'> {
    value?: string;
    onChange: (value: string) => void;
    regexp?: RegExp;
    inputRef?: RefObject<HTMLInputElement>;
    labelledby?: string;
    label?: string;
    required?: boolean;
    placeholder?: string;
    minLength?: number;
    maxLength: number;
    variant?: string;
}

export class ManagedInput extends Component<ManagedInputProps, any> {
    static defaultProps = {
        required: false,
        type: 'text',
    }
    inputRef: RefObject<HTMLInputElement>;

    constructor(props: ManagedInputProps) {
        super(props);
        if (props.inputRef) {
            this.inputRef = props.inputRef;
        }
        else {
            this.inputRef = React.createRef<HTMLInputElement>();
        }
    }

    componentDidMount() {
        this.setUpValidity();
    }

    componentDidUpdate(prevProps: Readonly<ManagedInputProps>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.value !== prevProps.value || this.props.required !== prevProps.required) {
            this.setUpValidity();
        }
    }

    handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const target = event.currentTarget;
        const value = target.value;
        target.setCustomValidity("");
        if(this.props.variant === 'zip') {
            let transformedValue = value.replace("-", "");
            if(transformedValue.length>5) {
                transformedValue = transformedValue.substr(0, 5) + "-" + transformedValue.substr(5, transformedValue.length - 5)
            }
            this.props.onChange(transformedValue);
        } else {
            this.props.onChange(value);
        }
    }

    setUpValidity = () => {
        this.inputRef?.current?.setCustomValidity("");
        if (!this.inputRef?.current?.checkValidity()) {
            this.inputRef?.current?.setCustomValidity("Invalid");
        }
    }

    render() {
        const inputMode = this.props.type === 'number' ? 'numeric' : undefined;
        const onKeyDown = this.props.type === 'number' ? (e: KeyboardEvent) => {
            //Numbers also allow e and . and bypasses our onchange event, so prevent e
            if (e.key === 'e') {
                e.preventDefault();
            }
        } : undefined;
        const {inputRef, labelledby, regexp, value, label, ...props} = this.props;
        return (
            <Form.Control
                pattern={regexp?.source}
                ref={this.inputRef}
                aria-labelledby={labelledby}
                aria-label={label}
                {...props}
                value={value ?? ""}
                onChange={this.handleChange}
                inputMode={inputMode}
                onKeyDown={onKeyDown}
            />
        );
    }
}