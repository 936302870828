import React, {SyntheticEvent} from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import {Identifiable} from "../Utilities/Models";
import Row from "react-bootstrap/Row";

export type UpdateFunction<T> = (item: Partial<T>) => void;

interface ListInputProps<T extends Identifiable> {
    items: T[];
    oldItems?: T[];
    display: (item: T, updateItem: UpdateFunction<T>) => JSX.Element;
    addNewItemPrompt: string;
    onItemsChange: (items: T[]) => void;
    readonlyDisplay?: (item: T) => JSX.Element;
    createNewItem: () => T;
    canAddNewItems?: boolean;
}
interface ListInputState {

}
class ListInput<T extends Identifiable> extends React.Component<ListInputProps<T>, ListInputState> {
    static defaultProps = {
        createNewItem: () => {
            return {};
        },
        canAddNewItems: true
    }
    handleAddNewItem = (event: SyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();

        this.props.onItemsChange([...this.props.items, this.props.createNewItem()]);
    }

    handleUpdateItem = (index: number) => (item: Partial<T>) => {
        let items = [...this.props.items];
        let originalItem = items[index];
        items.splice(index, 1, {...originalItem, ...item});
        this.props.onItemsChange(items);
    }

    handleRemoveItemAtIndex = (index: number) => (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        let items = [...this.props.items];
        items.splice(index, 1);
        this.props.onItemsChange(items);
    }

    wrapItemDisplay = (key: any, view?: JSX.Element, secondaryView?: JSX.Element) => {
        return (
            <Row key={key}>
                <Col xs={10} sm={11}>
                    {view}
                </Col>
                <Col xs={1} className="my-auto">
                    {secondaryView}
                </Col>
            </Row>);
    }

    render() {
        return (
            <div>
                {this.props.oldItems && this.props.oldItems.map((item, index) => {
                    return this.wrapItemDisplay(index, this.props.readonlyDisplay?.(item));
                })}
                {this.props.items.map((item, index) => {
                    let isReadonly: boolean | undefined = this.props.readonlyDisplay !== undefined && !!(item as any).offenderEntity;
                    let itemDisplay: undefined | JSX.Element;
                    let removeButton: undefined | JSX.Element;
                    if (isReadonly) {
                        itemDisplay = this.props.readonlyDisplay?.(item);
                    }
                    else {
                        itemDisplay = this.props.display(item, this.handleUpdateItem(index));
                        removeButton = (
                            <Button variant="danger"
                                onClick={this.handleRemoveItemAtIndex(index)}
                            >Remove</Button>
                        );
                    }
                    return this.wrapItemDisplay(index, itemDisplay, removeButton);
                })}
                {this.props.canAddNewItems &&
                <Form.Row>
                    <Col>
                        <Button onClick={this.handleAddNewItem}>
                            <Image src="plus.svg"/>
                            <span>{this.props.addNewItemPrompt}</span>
                        </Button>
                    </Col>
                </Form.Row>
                }
            </div>
        );
    }
}
export default ListInput;