import React from "react";
import Utilities, {CancelablePromise} from "../Utilities/Utilities";
import {ColProps, Form} from "react-bootstrap";
import DropDownInput from "./DropDownInput";
import {VehicleMake} from "../generated";


type VehicleDescriptor = VehicleMake;

interface VehicleTypeMakeAndModel {
    vehicleType?: VehicleDescriptor;
    vehicleMake?: VehicleDescriptor;
    vehicleModel?: VehicleDescriptor;
}
interface VehicleModelInputProps extends VehicleTypeMakeAndModel{
    onChange: (model: Partial<VehicleTypeMakeAndModel>) => void;
}
interface VehicleModelInputState {
    types: VehicleDescriptor[];
    makes: VehicleDescriptor[];
    models: VehicleDescriptor[];
    error: any | null;
}
export default class VehicleModelInput extends React.Component<VehicleModelInputProps, VehicleModelInputState> {
    state = {
        error: null,
        types: [],
        makes: [],
        models: []
    }
    request?: CancelablePromise<any> = undefined;
    componentWillUnmount() {
        this.request?.cancel();
    }
    componentDidMount() {
        this.getVehicleDescriptors('/lookup/vehicle/types', types => this.setState({types}));
        if (this.props.vehicleType?.id && this.props.vehicleType.id > 0) {
            this.getMakes();
        }
        if (this.props.vehicleMake?.id && this.props.vehicleMake.id > 0) {
            this.getModels();
        }
    }

    getMakes = () => {
        this.getVehicleDescriptors( `/lookup/vehicle/makes?type=${this.props.vehicleType?.id}`,
            makes => this.setState({makes}));
    }
    getModels = () => {
        this.getVehicleDescriptors(`/lookup/vehicle/models?make=${this.props.vehicleMake?.id}`,
            models => this.setState({models}));
    }
    getVehicleDescriptors = (url: string, callback:(descriptors: VehicleDescriptor[]) => void) => {
        this.request = Utilities.fetchJSON<VehicleDescriptor[]>({url, useCache: true});
        this.request.promise
            .then(descriptors => {
                callback(descriptors);
                this.request = undefined;
            })
            .catch((error: any) => {
                if (!error.isCanceled) {
                    this.setState({error});
                    this.request = undefined;
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<VehicleModelInputProps>, prevState: Readonly<VehicleModelInputState>, snapshot?: any) {
        if (prevProps.vehicleType !== this.props.vehicleType) {
            this.setState({makes: [], models: []});
            if (this.props.vehicleType?.id && this.props.vehicleType.id  > 0) {
                this.getMakes();
            }
        }
        if (prevProps.vehicleMake !== this.props.vehicleMake) {
            this.setState({models: []});
            if (this.props.vehicleMake?.id && this.props.vehicleMake.id > 0) {
                this.getModels();
            }
        }
    }

    render() {
        if (this.state.error) {
            return (
                <Form.Label>Error: {`${this.state.error}`}</Form.Label>
            );
        }
        const sharedProps = {
            displayKey: 'description' as keyof VehicleDescriptor,
            colProps: {sm: 4} as ColProps
        };
        return (
            <Form.Row>
                <DropDownInput
                    value={this.props.vehicleType}
                    onChange={vehicleType => this.props.onChange({vehicleType})}
                    options={this.state.types}
                    prompt='Vehicle Type'
                    id='type'
                    errormessage="You must select a vehicle type."
                    {...sharedProps}
                    useId
                ><Form.Text muted>Pick-up Trucks should be added as "Automobiles".</Form.Text></DropDownInput>
                <DropDownInput
                    prompt="Make of Vehicle"
                    value={this.props.vehicleMake}
                    onChange={vehicleMake => this.props.onChange({vehicleMake})}
                    options={this.state.makes}
                    id="make"
                    errormessage="You must select a vehicle make."
                    {...sharedProps}
                    useId
                />
                <DropDownInput
                    prompt="Model of Vehicle"
                    value={this.props.vehicleModel}
                    onChange={vehicleModel => this.props.onChange({vehicleModel})}
                    options={this.state.models}
                    id="model"
                    errormessage="You must select a vehicle model."
                    {...sharedProps}
                    useId
                />
            </Form.Row>
        );
    }
}