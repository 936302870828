import React from "react";
import {SectionNextButtonHandler} from "./SectionBase";
import {Address, AddressType, DocFacility, OffenderAddress} from "../generated";
import Form from "react-bootstrap/Form";
import AddressInput from "../Inputs/AddressInput";
import ListSectionBase from "./ListSectionBase";
import YesNoInput from "../Inputs/YesNoInput";
import AddressDisplay from "../DisplayOnly/AddressDisplay";
import CategoryValuePicker from "../Inputs/CategoryValuePicker";
import Collapse from "react-bootstrap/cjs/Collapse";
import DatePickerWrapper from "../Inputs/DatePickerWrapper";
import HelpModal from "../Modals/HelpModal";
import LookupValuePicker from "../Inputs/LookupValuePicker";
import TextInput from "../Inputs/TextInput";

const PrimaryAddressId = 1;
const MailingAddressId = 2;
const JuvenileAddressId = 3;
const FacilityAddressId = 5;
const PrimaryAddressIds = [PrimaryAddressId, JuvenileAddressId, FacilityAddressId];

const isJuvenileFacility = (a?: AddressType):boolean => a?.id === JuvenileAddressId;
const isFacility = (a?: AddressType):boolean => isJuvenileFacility(a) || a?.id === FacilityAddressId;
const isPrimary = (a?: AddressType):boolean => !!a?.id && PrimaryAddressIds.includes(a.id);


const AddressSection: React.FunctionComponent<SectionNextButtonHandler> = ({onButtonClick}) => {
    const [isHomeless, setIsHomeless] = React.useState(false);
    const [hasMailing, setHasMailing] = React.useState(false);
    const [showPrimaryAddressChange, setShowPrimaryAddressChange] = React.useState(false);

    const DisplayAddressInput = (address: Address, updateItem: (item: Partial<Address>) => void):JSX.Element => {
        if (isHomeless && address.homeless === undefined) {
            updateItem({homeless: false, addressType: {id: MailingAddressId}});
        }

        const showHomelessQuestion = (!isHomeless || address.homeless);

        if (!isHomeless && isPrimary(address.addressType)) {
            setShowPrimaryAddressChange(true);
        }

        return (
            <Form.Group>
                {showHomelessQuestion &&  <YesNoInput
                    question="Are you homeless?"
                    value={address.homeless}
                    onChange={homeless => {
                        let item = {
                            homeless,
                            longitude: undefined,
                            latitude: undefined
                        } as Partial<Address>;

                        if (homeless) {
                            item = {
                                ...item,
                                addressType: { id: 1, value: "PRI", description: "Primary" }
                            };
                        }
                        updateItem(item);
                    }}
                    feedback="You must answer this question."
                    id="homeless"
                />}
                <Form.Row>
                    <DatePickerWrapper
                        colProps={{sm:6}}
                        onChange={startDate => updateItem({startDate})}
                        prompt='Start Date'
                        value={address.startDate}
                        errormessage='You must enter the day you started using this address.'
                    />
                    {!address.homeless &&
                    <CategoryValuePicker<AddressType>
                        colProps={{sm:6}}
                        prompt="Address Type"
                        value={address.addressType}
                        onChange={addressType =>
                            updateItem({addressType, latitude: undefined, longitude: undefined})}
                        apiUrl='/lookup/addresstype'
                        displayKey='description'
                        id="addressType"
                        errormessage='You must select an address type.'
                        useId
                        readonly={!showHomelessQuestion}
                    />}
                </Form.Row>
                <Collapse in={address.homeless} unmountOnExit>
                    <div>
                        <Form.Row>
                            <TextInput
                                colProps={{sm:6}}
                                prompt="Zip Code"
                                placeholder="#####"
                                value={address.zip}
                                onChange={zip => updateItem({zip})}
                                minLength={5}
                                maxLength={10}
                                regexp={/(^\d{5}$)|(^\d{5}-\d{4}$)/}
                                id="zip"
                                variant={"zip"}
                                errormessage="You must enter a zip code."
                            />
                            <LookupValuePicker
                                colProps={{sm: 6}}
                                value={address.county}
                                onChange={county => updateItem({county})}
                                type='County'
                                id='county'
                                prompt='County'
                                errormessage="You must enter a county."
                                useId
                            />
                            {!hasMailing &&
                                <Form.Label>Do you have a mailing address where you can receive information?</Form.Label>
                            }
                        </Form.Row>
                    </div>
                </Collapse>
                <Collapse in={address.homeless === false && address.addressType !== undefined} unmountOnExit>
                    <div>
                        <Collapse in={isFacility(address.addressType)} unmountOnExit>
                            <div>
                                <Form.Row>
                                    <CategoryValuePicker<DocFacility>
                                        prompt="DOC Facility"
                                        value={address.facility}
                                        onChange={facility => updateItem({...address, facility})}
                                        apiUrl={`/lookup/facility/${isJuvenileFacility(address.addressType) ? 'juvenile' : 'treatment'}`}
                                        displayKey='name'
                                        id="facility"
                                        errormessage="You must select a facility."
                                        useId
                                    />
                                </Form.Row>
                            </div>
                        </Collapse>
                        <Collapse in={!isFacility(address.addressType)} unmountOnExit>
                            <div>
                                <AddressInput
                                    title="Street Address"
                                    address={address}
                                    onChange={newAddress => updateItem({...address, ...newAddress})}
                                    requireInputs={!!address.addressType}
                                />
                            </div>
                        </Collapse>
                    </div>
                </Collapse>
            </Form.Group>
        );
    }

    return (
        <ListSectionBase<Address, OffenderAddress>
            strings={{
                noOldItemsString: `No Previous Addresses Saved`,
                oldItemStatusPrompt: `Is this Address still accurate?`,
                currentItemsListTitle: `Update Your Addresses`,
                addNewItemPrompt: `Add ${isHomeless && !hasMailing ? 'Mailing ' : ''}Address`
            }}
            apiUrl='/address'
            onItemSubmit={onButtonClick}
            displayItemInput={DisplayAddressInput}
            displayItem={address => <AddressDisplay {...address} />}
            requiredItemPrompt="You must enter at least one primary address."
            onOldItemCurrentChange={(current, updateItem) => updateItem({current})}
            customValidator={addresses => {
                let primaryAddressCount = 0;
                let primaryAddressDescription: string | undefined = undefined;
                let types = {} as Record<number, any>;
                setIsHomeless(false);
                setHasMailing(false);
                for (let address of addresses) {
                    if (address.homeless) {
                        setIsHomeless(true);
                    }
                    let {addressType, startDate} = address;
                    if (addressType && startDate && new Date(startDate) < new Date()) {
                        if (addressType.id === MailingAddressId) {
                            setHasMailing(true);
                        }
                        if (types[addressType.id!]) {
                            return `You can only have one address of type '${addressType.description}' at a time. Please either set a future start date or a different address type.`;
                        }
                        types[addressType.id!] = address;
                        if (isPrimary(addressType)) {
                            primaryAddressCount += 1;
                            if (primaryAddressDescription) {
                                primaryAddressDescription += " or " + addressType.description;
                            }
                            else {
                                primaryAddressDescription = addressType.description;
                            }
                        }
                    }
                }
                if (primaryAddressCount > 1) {
                    return `You can only have one address of type '${primaryAddressDescription}' at a time. Please either set a future start date or a different address type.`;
                }
                if (primaryAddressCount < 1) {
                    return "One current primary address is permitted. One separate mailing address may be permitted upon approval.\n" +
                        "If you have additional addresses and/or questions, please contact SOR Central Office at 608-240-5830.";
                }

                return undefined;
            }}
            maxItems={3}
        >
            <HelpModal show={showPrimaryAddressChange}>
                Please remember to fill out a change of address with the U.S. Post Office in person or online
                at <a href='https://usps.com/' rel="noreferrer" target='_blank'>usps.com</a>.
            </HelpModal>
        </ListSectionBase>
    );
}

export default AddressSection;