import React from "react";
import Col, {ColProps} from "react-bootstrap/Col";
import InputLabelAndFeedback from "./InputLabelAndFeedback";
import {InputGroup, Form} from "react-bootstrap";
import {ManagedInput} from "./ManagedInput";

interface HeightInputProps {
    value?: string;
    onChange: (value: string) => void;
    colProps?: ColProps;
    prompt: string;
}
interface HeightInputState {
    feet?: string;
    inches?: string;
}
export default class HeightInput extends React.Component<HeightInputProps, HeightInputState> {
    constructor(props: HeightInputProps) {
        super(props);
        this.state = this.parseValue(props.value);
    }
    errorInputRef = React.createRef<HTMLInputElement>();
    inchesInputRef = React.createRef<HTMLInputElement>();

    componentDidUpdate(prevProps: Readonly<HeightInputProps>, prevState: Readonly<HeightInputState>, snapshot?: any) {
        if (this.props.value !== prevProps.value) {
            this.setState(this.parseValue(this.props.value));
        }
    }

    parseValue = (value?: string): HeightInputState => {
        let feet: string | undefined = undefined;
        let inches: string | undefined = undefined;

        if (value) {
            let match = /^(\d+)\.(\d+)$/.exec(value); //\s(\d+)\sin
            if (match && match[1]) {
                feet = `${parseInt(match[1], 10)}`;
                if (match[2]) {
                    inches = `${parseInt(match[2], 10)}`;
                }
            }
            else {
                match = /^\d$/.exec(value);
                if (match) {
                    feet = `${parseInt(match[0], 10)}`;
                }
            }
        }

        this.setCustomValidity(feet);
        return {feet, inches};
    }

    setCustomValidity = (feet?: string) => {
        let customValidity = "";
        if (feet && feet.length <= 0) {
            customValidity = "Invalid";
        }

        this.errorInputRef.current?.setCustomValidity(customValidity);
        this.inchesInputRef.current?.setCustomValidity(customValidity);
    }

    handleChange = (ft?: string, inches?: string) => {
        const inchesString = inches ? `.${inches.padStart(2, "0")}` : "";
        this.props.onChange(`${ft ?? 0}${inchesString}`);
    }

    render() {
        return (
            <InputLabelAndFeedback
                colProps={this.props.colProps}
                prompt="Height"
                id="height"
            >
                <Form.Row>
                    <Col>
                        <InputGroup>
                            <ManagedInput
                                onChange={ft => this.handleChange(ft, this.state.inches)}
                                value={this.state.feet}
                                regexp={/^[2-8]+$/}
                                maxLength={1}
                                minLength={1}
                                required
                                labelledby="height feet"
                                placeholder='-'
                                type='number'
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="feet" aria-label="feet">ft</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup>
                            <ManagedInput
                                onChange={inches => this.handleChange(this.state.feet, inches)}
                                value={this.state.inches}
                                regexp={/^[0-9]|1[0-2]$/}
                                maxLength={2}
                                minLength={0}
                                labelledby="height inches"
                                placeholder='--'
                                inputRef={this.inchesInputRef}
                                required={false}
                                type='number'
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="inches" aria-label="inches">in</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Form.Row>
                <Form.Control style={{display: 'none'}} ref={this.errorInputRef}/>
                <Form.Control.Feedback type="invalid">You must enter a height.</Form.Control.Feedback>
            </InputLabelAndFeedback>
        );
    }
}