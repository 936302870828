import React from "react";
import {ColProps} from "react-bootstrap/Col";
import {ManagedInput, ManagedInputProps} from "./ManagedInput";
import InputLabelAndFeedback from "./InputLabelAndFeedback";

export interface TextInputProps extends ManagedInputProps {
    prompt: string;
    value?: string;
    onChange(value: string): void;
    placeholder?: string;
    colProps?: ColProps;
    errormessage?: string;
    type?: string;
    regexp?: RegExp;
    id: string;
    variant?: string;
}

const TextInput: React.FunctionComponent<TextInputProps> = (
    {
        prompt,
        value,
        onChange,
        placeholder,
        colProps,
        errormessage,
        type,
        regexp,
        id,
        ...otherProps
    }) => {

    return (
        <InputLabelAndFeedback
            colProps={colProps}
            prompt={prompt}
            errormessage={errormessage}
            id={id}
        >
            <ManagedInput
                placeholder={(placeholder === undefined ? prompt : placeholder)}
                onChange={onChange}
                value={value}
                regexp={regexp}
                required={errormessage !== undefined}
                type={type}
                labelledby={id}
                {...otherProps}
            />
        </InputLabelAndFeedback>
    );
}
export default TextInput;