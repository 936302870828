import React from "react";
import ListSectionBase, {ListSectionBaseProps} from "./ListSectionBase";
import {DOCOriginal, DOCSubmittable} from "../Utilities/Models";
import {UpdateFunction} from "../Inputs/ListInput";
import Modal from "react-bootstrap/Modal";
import DatePickerWrapper from "../Inputs/DatePickerWrapper";
import Button from "react-bootstrap/Button";

interface BaseEndDateSectionProps<T extends DOCSubmittable, I extends DOCOriginal<T>> extends Omit<ListSectionBaseProps<T, I>, 'onOldItemCurrentChange'>{
    endDatePromptTitle: string;
}

interface BaseEndDateSectionState<T extends DOCSubmittable> {
    startDate?: string;
    endDate?: string;
    updateFunction?: UpdateFunction<T>;
}
export default class BaseEndDateSection<T extends DOCSubmittable, I extends DOCOriginal<T>> extends
    React.Component<BaseEndDateSectionProps<T, I>, BaseEndDateSectionState<T>> {
    static defaultProps = {
        getItemIsCurrent: (item: { endDate?: string; }) => !item.endDate
    }
    state = {
        endDate: undefined,
        updateFunction: undefined
    } as BaseEndDateSectionState<T>;
    render() {
        return (
            <ListSectionBase<T, I>
                {...this.props}
                onOldItemCurrentChange={(current, updateFunction, item) => {
                    if (current) {
                        updateFunction({endDate: undefined} as Partial<T>);
                    }
                    else {
                        this.setState({updateFunction, startDate: item.startDate});
                    }
                }}
            >
                <Modal show={this.state.updateFunction !== undefined}>
                    <Modal.Header>{this.props.endDatePromptTitle}</Modal.Header>
                    <Modal.Body>
                        <DatePickerWrapper
                            prompt="End Date"
                            value={this.state.endDate}
                            onChange={endDate => this.setState({endDate})}
                            errormessage="You must enter a end date."
                            startDate={this.state.startDate}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={() => {
                                if(this.state.endDate) {
                                    this.state.updateFunction?.({endDate: this.state.endDate} as Partial<T>);
                                }
                                this.setState({
                                    endDate: undefined,
                                    updateFunction: undefined
                                });
                            }}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </ListSectionBase>
        );
    }
}