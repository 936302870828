import { Marking } from "generated";
import React from "react";
import Col from "react-bootstrap/cjs/Col";
import Row from "react-bootstrap/cjs/Row";

const MarkingDisplay: React.FunctionComponent<Marking> = (marking) => {
    return (
        <Row>
            <Col>
                {marking.type}
                <span className='font-italic'> on </span>
                {`${marking.location}, ${marking.description}.`}
            </Col>
        </Row>
    );
}
export default MarkingDisplay;