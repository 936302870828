import React from 'react';
import LookupValuePicker from "./LookupValuePicker";
import TextInput from "./TextInput";
import Collapse from "react-bootstrap/Collapse";
import {LookupValue} from "../generated";
import Col from "react-bootstrap/cjs/Col";
import { Form } from 'react-bootstrap';
import {City} from "../Utilities/Models";
import {isCityOther, isCityOutOfState, OutOfStateCity} from 'Utilities/Locations';

interface CityInputProps extends City {
    onChange: (city?: LookupValue, outOfStateCity?: string) => void;
    inWisconsin: boolean;
    requireInputs?: boolean;
}
interface CityInputState {
    lastCity?: LookupValue;
    lastOtherCity?: string;
}

class CityInput extends React.Component<CityInputProps, CityInputState> {

    cityIsOther = ():boolean => isCityOther(this.props.city);
    cityIsOutOfState = ():boolean => isCityOutOfState(this.props.city);

    displayOtherCity = (): boolean => {
        return !this.props.inWisconsin ||
            this.cityIsOther() ||
            this.cityIsOutOfState();
    }

    handleChange = ({city = this.props.city, outOfStateCity = this.props.outOfStateCity}) => {
        this.props.onChange(city, outOfStateCity)
    }

    componentDidUpdate(prevProps: Readonly<CityInputProps>, prevState: Readonly<CityInputState>, snapshot?: any) {
        if (prevProps.inWisconsin !== this.props.inWisconsin) {
            if (this.props.inWisconsin) {
                let {city, outOfStateCity} = this.props;
                if (!this.cityIsOther() && this.cityIsOutOfState() && (this.props.outOfStateCity?.length ?? 0) > 0) {
                    outOfStateCity = undefined;
                }
                if (this.cityIsOutOfState()){
                    city = undefined;
                }
                this.props.onChange(city, outOfStateCity);
            }
            else if (!this.cityIsOutOfState()) {
                this.props.onChange(OutOfStateCity, this.props.outOfStateCity);
            }
        }
    }

    render() {
        return (
                <Col sm={4}>
                    <Collapse in={this.props.inWisconsin}>
                        <div>
                            <Form.Row>
                                <LookupValuePicker
                                    value={this.props.city}
                                    onChange={city => this.handleChange({city})}
                                    type='City'
                                    id='city'
                                    prompt='City'
                                    errormessage={this.props.requireInputs ? "You must select a city." : undefined}
                                    isInvalid={this.props.inWisconsin && this.props.city?.value === OutOfStateCity.value}
                                    useId
                                >
                                    <Form.Text muted>If not found select 'Other' or 'Out of State'</Form.Text>
                                </LookupValuePicker>
                            </Form.Row>
                        </div>
                    </Collapse>
                    <Collapse in={this.displayOtherCity()} unmountOnExit>
                        <div>
                            <Form.Row>
                            <TextInput
                                prompt={this.props.inWisconsin ? "Other City" : "City"}
                                value={this.props.outOfStateCity}
                                onChange={otherCity => this.props.onChange(this.props.city, otherCity)}
                                errormessage={this.props.requireInputs ? "You must enter a city." : undefined}
                                id="otherCity"
                                maxLength={50}
                            />
                            </Form.Row>
                        </div>
                    </Collapse>
                </Col>
        );
    }
}
export default CityInput;
