import React from "react";
import {Form} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import YesNoInput, {YesNoInputProps} from "./YesNoInput";


const EntityStatusInput: React.FunctionComponent<YesNoInputProps> = ({children, ...yesNoProps}) => {
    return (
        <Form.Group>
            <Card>
                <Card.Header>
                    <YesNoInput feedback="You must answer this question." {...yesNoProps} />
                </Card.Header>
                {children &&
                <Card.Body>
                    {children}
                </Card.Body>
                }
            </Card>
        </Form.Group>
    );
}
export default EntityStatusInput;