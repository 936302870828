import React from "react";
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col, {ColProps} from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

export interface YesNoInputProps {
    question: string;
    value?: boolean;
    onChange: (value: boolean) => void;
    feedback?: string;
    id: string;
    questionColProps?:ColProps;
    answerColProps?:ColProps;
}
const YesNoInput: React.FunctionComponent<YesNoInputProps> = ({question, value, onChange, feedback, id,
                                                                  questionColProps = {xs: 6, sm:'auto'},
                                                                  answerColProps = {xs: 6, sm:'auto'}}) => {
    const hiddenRef = React.createRef<HTMLInputElement>();

    React.useEffect(() => {
        if (value === undefined) {
            hiddenRef.current?.setCustomValidity("Invalid");
        }
        else {
            hiddenRef.current?.setCustomValidity("");
        }
    }, [value, hiddenRef]);

    return (
        <div>
            <Form.Row className='align-items-center'>
                <Col {...questionColProps}>
                    <Form.Label
                        id={id}
                        className='mb-0'
                    >{question}</Form.Label>
                </Col>
                <Col {...answerColProps}>
                    <ButtonGroup toggle aria-labelledby={id}>
                        {[
                            {text:"Yes", boolValue:true},
                            {text:"No", boolValue:false}
                        ].map(({text, boolValue}, idx) => {
                            const checked = value === boolValue;
                            return (
                                <ToggleButton
                                    key={idx}
                                    type="radio"
                                    variant="outline-secondary"
                                    name={text}
                                    value={idx}
                                    checked={checked}
                                    onChange={() => onChange(boolValue)}
                                >
                                    {text}
                                </ToggleButton>
                            );
                        })}
                    </ButtonGroup>
                </Col>
            </Form.Row>
            <Form.Row className='mb-1'>
                <Col>
                    <input ref={hiddenRef} style={{display: "none"}}/>
                    {feedback &&
                    <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>}
                </Col>
            </Form.Row>
        </div>
    );
}
export default YesNoInput;