import React, {ChangeEvent} from "react";
import Form from "react-bootstrap/Form";
import InputLabelAndFeedback, {InputLabelAndFeedbackProps} from "./InputLabelAndFeedback";

interface HasID {
    id?: number;
}

interface DropDownInputBaseProps<T extends HasID> extends InputLabelAndFeedbackProps {
    options: T[];
    displayKey: keyof T;
    nullValue: string;
    isInvalid?: boolean;
    readonly?: boolean;
    defaultValue?: T;
}

export interface UseIdDropDownProps<T extends HasID> extends DropDownInputBaseProps<T>{
    useId: true;
    onChange: (value?: T) => void;
    value?: T;
}
export interface UseValueDropDownProps<T extends HasID> extends DropDownInputBaseProps<T> {
    useId: false;
    onChange: (value?: string) => void;
    value?: string;
}

interface DropDownInputState {

}
export default class DropDownInput<T extends HasID> extends React.Component<UseIdDropDownProps<T> | UseValueDropDownProps<T>, DropDownInputState> {
    static defaultProps = {
        useId: false,
        nullValue: '---'
    }
    ref = React.createRef<HTMLSelectElement>();
    componentDidMount() {
        if (this.props.defaultValue && !this.props.value) {
            if (this.props.useId) {
                this.props.onChange(this.props.defaultValue);
            }
            else {
                this.props.onChange(String(this.props.defaultValue.id));
            }
        }
        this.setValidity();
    }

    componentDidUpdate(prevProps: Readonly<UseIdDropDownProps<T> | UseValueDropDownProps<T>>, prevState: Readonly<DropDownInputState>, snapshot?: any) {
        if (prevProps.value !== this.props.value || prevProps.errormessage !== this.props.errormessage
            || prevProps.isInvalid !== this.props.isInvalid) {
            this.setValidity();
        }
        if (prevProps.options !== this.props.options && !this.valueIsBlank()) {
            if (this.ref.current?.selectedIndex === 0 && !this.props.defaultValue) {
                this.ref.current?.setCustomValidity("Invalid");
            }
            else {
                this.ref.current?.setCustomValidity("");
            }
        }
    }

    valueIsBlank = () => !this.props.value || this.props.value === ''

    setValidity = () => {
        if (this.ref.current) {
            let validity = "";
            if (this.props.isInvalid || (this.props.errormessage && this.valueIsBlank() && !this.props.defaultValue)) {
                validity = "Invalid";
            }

            this.ref.current.setCustomValidity(validity);
        }
    }

    handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const index = event.currentTarget.selectedIndex -  (this.props.defaultValue ? 0 : 1);
        if (index >= 0) {
            const option = this.props.options[index];
            if (this.props.useId) {
                this.props.onChange(option);
            }
            else {
                this.props.onChange(event.currentTarget.value);
            }
        }
        else {
            this.props.onChange(undefined);
        }
    }

    render() {
        const getIDValue = (opt?: T): string | number => {
            if (opt?.id) {
                return opt.id;
            }
            if (this.props.defaultValue?.id) {
                return this.props.defaultValue.id;
            }
            return -1;
        };
        const getDisplayValue = (opt?: T): string | number => {
            if (opt)  {
                return String(opt[this.props.displayKey]);
            }
            else if (this.props.defaultValue) {
                return String(this.props.defaultValue[this.props.displayKey]);
            }
            else {
                return this.props.nullValue;
            }
        };
        const getValue = this.props.useId ? getIDValue : getDisplayValue;

        return (
            <InputLabelAndFeedback
                prompt={this.props.prompt}
                colProps={this.props.colProps}
                errormessage={this.props.errormessage}
                id={this.props.id}
            >
                <Form.Control
                    as="select"
                    value={this.props.useId ? getIDValue(this.props.value) : (this.props.value ?? "")}
                    onChange={this.handleChange}
                    required={this.props.errormessage !== undefined}
                    ref={this.ref}
                    aria-labelledby={this.props.id}
                    custom
                    disabled={this.props.readonly || this.props.options.length === 0}
                >
                    {!this.props.defaultValue && <option value={-1}>{this.props.nullValue}</option>}
                    {this.props.options.map( (option: T) => {
                        return <option key={option.id} value={getValue(option)}>{option[this.props.displayKey]}</option>;
                    })}
                </Form.Control>
                {this.props.children}
            </InputLabelAndFeedback>
        );
    }
}