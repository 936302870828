import React, {SyntheticEvent} from "react";
import {BaseAddress} from "../Utilities/Models";
import Button from "react-bootstrap/Button";
import {Form} from "react-bootstrap";
import GeocoderModal from "./GeocoderModal";
import InputLabelAndFeedback from "../Inputs/InputLabelAndFeedback";
import Utilities from "../Utilities/Utilities";


interface GeocoderProps {
    address: BaseAddress;
    onUpdate: (address: BaseAddress) => void;
}

const Geocoder: React.FunctionComponent<GeocoderProps> = ({address, onUpdate}) => {
    const [lookupAddress, setLookupAddress] = React.useState<BaseAddress>();

    const handleClickLookup = React.useCallback((event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setLookupAddress(address);
    }, [address]);

    const inputRef = React.createRef<HTMLInputElement>();
    const { street, state, city, outOfStateCity, zip, county } = address;

    const getGeocoderEnabled = React.useCallback(():boolean => {
        return (street?.length ?? 0) > 2 &&
            (state?.name?.length ?? 0) > 0 &&
            (((city?.id ?? 0) > 0 && city?.value?.toLowerCase() !== 'out of state') ||
                (outOfStateCity?.length ?? 0) > 0) &&
            (zip?.length ?? 0) >= 5 &&
            (county?.id ?? 0) > 0;
    }, [street, state, city, outOfStateCity, zip, county]);

    const verified = Utilities.addressIsEditable(address);
    React.useEffect(() => {
        if (verified) {
            inputRef.current?.setCustomValidity("");
        }
        else {
            inputRef.current?.setCustomValidity("Invalid");
        }
    }, [verified, inputRef]);

    return (
        <div>
            <InputLabelAndFeedback
                prompt='Validate Address'
                errormessage='You must verify the address.'
                id="geocoder"
            >
                <Form.Control
                    as={Button}
                    variant='success'
                    disabled={!getGeocoderEnabled()}
                    onClick={handleClickLookup}
                >{getGeocoderEnabled() && verified ? "Verified" : "Verify"}</Form.Control>
                <Form.Control ref={inputRef} style={{display: "none"}} />
            </InputLabelAndFeedback>
            <GeocoderModal lookupAddress={lookupAddress} onSelectAddress={a => {
                setLookupAddress(undefined);
                onUpdate(a);
            }} />
        </div>
    );
}
export default Geocoder;
