import React from "react";

interface DateDisplayProps {
    value?:string;
}

const DateDisplay: React.FunctionComponent<DateDisplayProps> = ({value}) => {
    if(!value) {
        return null;
    }

    const dateParts = value.split("-")
    return (<React.Fragment>{`${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`}</React.Fragment>);
}
export default DateDisplay;