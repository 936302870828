import EntityDisplay from "./EntityDisplay";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import {OffenderEmployer, OffenderSchool} from "../generated";
import DateDisplay from "./DateDisplay";

export default function EmployerDisplay(props: OffenderEmployer | OffenderSchool) {
    const endDate = props.endDate ?? props.updateEntity?.endDate;
    return (
        <div>
            <EntityDisplay {...props} isCurrent={!endDate}>
            </EntityDisplay>
            {endDate &&
            <Row>
                <Col xs={5} sm={5} md={6} lg={4}>End Date:</Col>
                <Col><DateDisplay value={endDate} /></Col>
            </Row>
            }
        </div>
    );
}