import React from "react";
import Form from "react-bootstrap/Form";
import AddressInput from "./AddressInput";
import TextInput from "./TextInput";
import {Entity} from "../Utilities/Models";
import NumberInput from "./NumberInput";
import Collapse from "react-bootstrap/cjs/Collapse";


export interface EntityInputProps<T extends Entity> {
    entityNamePrompt: string | JSX.Element;
    entityPhonePrompt: string;
    entity: T;
    onChange: (entity: T) => void;
    collapseAddressSection?: boolean;
    noCounty?: boolean;
    noGeocoder?: boolean;
    requireAddress?: boolean;
}

const EntityInput = <T extends Entity>(
    {
        entityNamePrompt,
        entityPhonePrompt,
        entity,
        onChange,
        collapseAddressSection,
        noCounty,
        noGeocoder,
        requireAddress = true,
        children
}: React.PropsWithChildren<EntityInputProps<T>>) => {
    const handleTelephoneChange = (phoneNumber: string) => {
        onChange({...entity, phoneNumber});
    };

    return (
        <div>
            <Form.Row>
                {typeof  entityNamePrompt === 'string' ?
                    <TextInput
                        prompt={entityNamePrompt}
                        placeholder="Name"
                        value={entity.name}
                        onChange={name => onChange({...entity, name})}
                        id="name"
                        errormessage="You must enter a name."
                        maxLength={100}
                    />
                    : entityNamePrompt}
            </Form.Row>
            <Form.Row>
                <NumberInput
                    colProps={{md: 6}}
                    prompt={entityPhonePrompt}
                    placeholder="###-###-####"
                    value={entity.phoneNumber}
                    onChange={handleTelephoneChange}
                    id="phone"
                    type="phone"
                    regexp={/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/}
                    maxLength={20}
                />
                {children}
            </Form.Row>
            <Collapse in={!collapseAddressSection} unmountOnExit>
                <div>
                    <AddressInput
                        address={entity}
                        onChange={address => onChange({...entity, ...address})}
                        noCounty={noCounty}
                        noGeocoder={noGeocoder}
                        requireInputs={requireAddress}
                    />
                </div>
            </Collapse>
        </div>
    );
}
export default EntityInput;