import {Phone} from "../generated";
import {Form} from "react-bootstrap";
import LookupValuePicker from "./LookupValuePicker";
import NumberInput from "./NumberInput";
import TextInput from "./TextInput";
import Collapse from "react-bootstrap/Collapse";

interface PhoneInputProps {
    phone: Phone;
    updateItem: (item: Partial<Phone>) => void;
    isTypeInvalid?: boolean;
}
const PhoneInput = ({phone, updateItem, isTypeInvalid}: PhoneInputProps) => {
    return (
        <Form.Group>
            <Form.Row>
                <LookupValuePicker
                    prompt="Type"
                    value={phone.type}
                    onChange={type => {
                        let partial = {type} as Partial<Phone>;
                        if (type && type.value !== 'Other') {
                            partial.otherDescription = undefined;
                        }
                        updateItem(partial);
                    }}
                    colProps={{lg: 5}}
                    id="phoneType"
                    type="Phone Type"
                    errormessage="You must select a phone type."
                    isInvalid={isTypeInvalid}
                    useId
                />
                <NumberInput
                    prompt="Phone Number"
                    placeholder="###-###-####"
                    value={phone.number}
                    onChange={number => updateItem({number})}
                    minLength={7}
                    colProps={{lg: 7}}
                    type="phone"
                    errormessage="You must enter the full 10-digit number."
                    regexp={/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/}
                    id="phone"
                    maxLength={20}
                />
            </Form.Row>
            <Collapse in={phone.type?.value === 'Other'} unmountOnExit>
                <div>
                    <Form.Row>
                        <TextInput
                            prompt="Description"
                            placeholder="Phone Type"
                            value={phone.otherDescription}
                            onChange={otherDescription => updateItem({otherDescription})}
                            id='otherDescription'
                            errormessage="You must enter a description."
                            maxLength={100}
                        />
                    </Form.Row>
                </div>
            </Collapse>
        </Form.Group>
    );
}
export default PhoneInput;