import React from "react";
import TextInput from "./TextInput";
import {Form} from "react-bootstrap";
import DropDownInput from "./DropDownInput";
import {Marking, MarkingType, MarkingLocation} from "../generated";
import Row from "react-bootstrap/Row";

interface MarkInputProps {
    marking: Marking;
    updateItem: (item: Partial<Marking>) => void;
    markingTypes: MarkingType[];
    markingType?: MarkingType;
}
const MarkingInput: React.FunctionComponent<MarkInputProps> = ({marking, updateItem, markingTypes, markingType}) => {
    return (
        <Form.Group as={Row}>
            <DropDownInput<MarkingType>
                displayKey='value'
                onChange={type => updateItem({type: type?.value, location: undefined})}
                prompt='Marking Type'
                id='markingType'
                options={markingTypes}
                nullValue='---'
                value={markingType}
                errormessage="You must select a marking type."
                useId
                colProps={{lg: 6}}
            />
            <DropDownInput<MarkingLocation>
                displayKey='description'
                onChange={(location?: string) => updateItem({location})}
                prompt='Marking Location'
                id='markingLocation'
                options={markingType?.markingLocations ? markingType.markingLocations : [] as MarkingLocation[]}
                nullValue='---'
                value={marking.location}
                errormessage="You must select a marking location."
                colProps={{lg: 6}}
            />
            <TextInput
                prompt="Description"
                value={marking.description}
                onChange={description => updateItem({description})}
                id='description'
                errormessage="You must enter a description."
                maxLength={200}
            />
        </Form.Group>
    );
}

export default MarkingInput;