import React from "react";
import DropDownInput, {UseIdDropDownProps, UseValueDropDownProps} from "./DropDownInput";
import Utilities, {CancelablePromise} from "../Utilities/Utilities";
import { Form } from "react-bootstrap";
import {USState} from "../generated";
import {Wisconsin} from "../Utilities/Locations";


type StatePickerProps = UseIdDropDownProps<USState> | UseValueDropDownProps<USState>;
interface StatePickerState {
    error: any | null;
    states: USState[];
}
let USStates: USState[] = [];
export default class StatePicker extends React.Component<StatePickerProps, StatePickerState> {
    state = {
        error: null,
        states: USStates
    }
    static defaultProps = {
        prompt: "State",
        colProps: {sm: 4},
        options: [],
        id: "state",
        displayKey: "name",
        useId: false,
        nullValue: '---'
    };

    request?: CancelablePromise<any> = undefined;
    componentWillUnmount() {
        this.request?.cancel();
    }
    componentDidMount() {
        if (USStates.length === 0) {
            this.request = Utilities.fetchJSON<USState[]>({url: '/lookup/states', useCache: true});
            this.request.promise
                .then(states => {
                    USStates = states;
                    this.setState({states: states});
                    this.request = undefined;
                }).catch(error => {
                    if (!error.isCanceled) {
                        this.setState({error});
                        this.request = undefined;
                    }
            });
        }
    }

    render() {
        if (this.state.error) {
            return (
                <Form.Label>Unable to load states</Form.Label>
            );
        }

        return (
            <DropDownInput<USState>
                {...this.props}
                options={this.state.states}
                defaultValue={Wisconsin}
            />
        );
    }
}