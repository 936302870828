import React, {useEffect} from "react";
import LoadingSpinner from "../Modals/LoadingSpinner";
import Fade from "react-bootstrap/Fade";

interface LoadingContainerProps {
    loading: boolean;
}

const LoadingContainer: React.FunctionComponent<LoadingContainerProps> = (({loading, children}) => {
    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {
        if (!loading && !loaded) {
            setLoaded(true);
        }
    }, [loaded, loading])

    return (
        <div>
            {loading && <LoadingSpinner />}
            <Fade in={loaded}>
                <div>
                    {children}
                </div>
            </Fade>
        </div>);
});

export default LoadingContainer;