import EntityDisplay from "./EntityDisplay";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import {EmergencyContact} from "../generated";
import Utilities from "../Utilities/Utilities";

export const EmergencyContactDisplay = (props: EmergencyContact) => {
    return (
        <EntityDisplay {...props} name={Utilities.parseName(props)} isCurrent={props.current}>
            <Row>
                <Col xs={5} sm={5} md={6} lg={4}>Relationship:</Col>
                <Col>{props.relationship}</Col>
            </Row>
        </EntityDisplay>
    );
}