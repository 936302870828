import React, {CSSProperties} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Phone} from "../generated";

interface PhoneDisplayProps extends Phone {
    isTypeInvalid?:boolean;
}

const PhoneDisplay: React.FunctionComponent<PhoneDisplayProps> = ({type, number, isTypeInvalid, otherDescription, current}) => {
    let style = {} as CSSProperties;
    if (isTypeInvalid) {
        style = {
            border: "1px solid red"
        }
    }

    return (
        <Row style={current !== false ? undefined : {textDecoration: 'line-through'}}>
            <Col sm={4} style={style}>{type?.value}{type?.id === 648 && `: ${otherDescription}`}</Col>
            <Col sm={8}>{number}</Col>
        </Row>
    );
}
export default PhoneDisplay;