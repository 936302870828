import React, {SyntheticEvent} from "react";
import './SignatureModal.css';
import Modal from "react-bootstrap/Modal";
import Button, {ButtonProps} from "react-bootstrap/Button";
import SignatureCanvas from "react-signature-canvas";
import ReactSignatureCanvas from "react-signature-canvas";
import Row from "react-bootstrap/Row";

interface SignatureModalProps {
    signature?: string;
    onSignatureAccept: (signature?: string) => void;
    buttonProps?: ButtonProps;
}
const SignatureModal: React.FunctionComponent<SignatureModalProps> = ({signature, onSignatureAccept, buttonProps}) => {
    let [show, setShow] = React.useState(false);

    const signatureCanvas = React.createRef<ReactSignatureCanvas>();
    const handleClear = (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        signatureCanvas.current?.clear();
    }

    const handleClose = (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (signatureCanvas.current) {
            if (signatureCanvas.current.isEmpty()
                || signatureCanvas.current.toData().flatMap(d => d).length<10
                || signatureCanvas.current.getTrimmedCanvas().width<15
                || signatureCanvas.current.getTrimmedCanvas().height<15
            ) {
                alert("You entered an invalid signature. Please provide a valid signature before submitting your updates.")
                onSignatureAccept(undefined);
            }
            else {
                onSignatureAccept(signatureCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
            }
        }
        setShow(false);
    }

    const handleShow = (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setShow(true);
    }

    React.useEffect(() => {
        if (signature) {
            signatureCanvas.current?.fromDataURL(signature, {
                ratio: 1,
            });
        }
        else {
            signatureCanvas.current?.clear();
        }
    }, [signature, signatureCanvas]);

    const sigSizeRef = React.createRef<HTMLDivElement>();
    const [width, setWidth] = React.useState(600);

    React.useEffect(() => {
        const handleWindowResize = () => {
            setWidth(sigSizeRef.current?.offsetWidth ?? 400);
        };

        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        }
    }, [sigSizeRef]);

    return (
        <div>
            <Button
                onClick={handleShow}
                {...buttonProps}
            >
                {signature !== undefined ?
                    "Update Signature" :
                    "Click Here to Sign The Agreement"
                }
            </Button>
            <Modal
                show={show}
                centered
                backdrop='static'
                size='xl'
            >
                <Modal.Header closeButton onHide={() => setShow(false)}>
                    <Modal.Title>Please Sign Below</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row ref={sigSizeRef}>
                        <SignatureCanvas
                            canvasProps={{
                                id: 'canvas',
                                height: 280,
                                width
                            }}
                            ref={signatureCanvas}
                        />
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={handleClose}
                        // disabled={!this.signatureCanvas.current || this.signatureCanvas.current.isEmpty()}
                    >Save</Button>
                    <Button
                        variant="secondary"
                        onClick={handleClear}
                        // disabled={!this.signatureCanvas.current || this.signatureCanvas.current.isEmpty()}
                    >Clear</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default SignatureModal;
