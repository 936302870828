import React from "react";
import TextInput from "./TextInput";
import { Form } from "react-bootstrap";
import {NamesDetail} from "../generated";
import LookupValuePicker from "./LookupValuePicker";
import Row from "react-bootstrap/Row";

interface NamesDetailInputProps extends NamesDetail {
    updateItem: (item: Partial<NamesDetail>) => void;
}
const NamesDetailInput: React.FunctionComponent<NamesDetailInputProps> = (props) => {
    return (
        <Form.Group as={Row}>
            <TextInput
                prompt="Last Name"
                value={props.lastName}
                onChange={lastName => props.updateItem({lastName})}
                id='lastName'
                colProps={{lg: 4, md: 6}}
                errormessage="You must enter a last name."
                maxLength={50}
            />
            <TextInput
                prompt="First Name"
                value={props.firstName}
                onChange={firstName => props.updateItem({firstName})}
                id='firstName'
                colProps={{lg: 3, md: 6}}
                maxLength={50}
            />
            <TextInput
                prompt="Middle Name"
                value={props.middleName}
                onChange={middleName => props.updateItem({middleName})}
                id='middleName'
                colProps={{lg: 3, md: 6}}
                maxLength={50}
            />
            <LookupValuePicker
                value={props.suffixName}
                useId={false}
                onChange={suffixName => props.updateItem({suffixName})}
                type="Suffix"
                prompt="Suffix"
                id='suffix'
                colProps={{lg: 2, md: 6}}
            />
        </Form.Group>
    );
}
export default NamesDetailInput;