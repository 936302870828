import React, {SyntheticEvent} from "react";
import {Form} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Session from "../Utilities/Session";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Utilities, {CancelablePromise} from "../Utilities/Utilities";
import ErrorModal, {ErrorModalButtonInfo} from "../Modals/ErrorModal";
import PhoneDisplay from "../DisplayOnly/PhoneDisplay";
import AddressDisplay from "../DisplayOnly/AddressDisplay";
import EmployerDisplay from "../DisplayOnly/EmployerDisplay";
import {EmergencyContactDisplay} from "../DisplayOnly/EmergencyContactDisplay";
import VehicleDisplay from "../DisplayOnly/VehicleDisplay";
import {InternetUsageDisplay} from "../DisplayOnly/InternetUsageDisplay";
import SignatureModal from "../Modals/SignatureModal";
import {SectionNextButtonHandler} from "./SectionBase";
import {Identification, PortalSubmission} from "../generated";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../Modals/LoadingSpinner";
import MarkingDisplay from "../DisplayOnly/MarkingDisplay";

type ReviewPageProps = SectionNextButtonHandler;
type Registrant = PortalSubmission;
interface ReviewPageState {
    loading: boolean;
    error?: any;
    registrant?: Registrant;
    agreed: boolean;
    signature?: string;
    errorButtons?: ErrorModalButtonInfo[];
}
export default class ReviewPage extends React.Component<ReviewPageProps, ReviewPageState> {
    state:ReviewPageState = {
        loading: true,
        error: undefined,
        registrant: undefined,
        agreed: false,
        signature: undefined,
        errorButtons: undefined
    };

    request?: CancelablePromise<any> = undefined;
    componentWillUnmount() {
        this.request?.cancel();
    }
    componentDidMount() {
        this.requestData();
    }
    requestData = () => {
        this.request = Utilities.fetchJSON<Registrant>({url: '/allinfo'});
        this.request.promise
            .then(registrant => {
                this.setState({registrant, loading: false});
                this.request = undefined;
            })
            .catch((error: any) => {
                if (!error.isCanceled) {
                    this.setState({
                        loading: false,
                        error,
                        errorButtons: this.retryButton(this.requestData)
                    });
                    this.request = undefined;
                }
            });
    }

    retryButton = (onClick: () => void): ErrorModalButtonInfo[] => {
        return [{
            text: 'Retry',
            variant: 'secondary',
            onClick: () => {
                this.setState({loading: true, error: undefined, errorButtons: undefined});
                onClick();
            }
        }];
    }

    onSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({loading: true});
        this.request = Utilities.fetchJSON<Registrant>({
            url: '/submit',
            body: this.state.signature
        });
        this.request.promise
            .then(() => {
                this.setState({agreed: true, loading: false});
                this.request = undefined;
            })
            .catch(error => {
                if (!error.isCanceled) {
                    this.setState({error, loading: false});
                    this.request = undefined;
                }
            });
    }

    handleFileUpload = (event: SyntheticEvent<HTMLInputElement>) => {
        const {files} = event.currentTarget;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (fileReaderEvent: ProgressEvent<FileReader>) => {
                if (fileReaderEvent.target) {
                    const signature = fileReaderEvent.target.result;
                    if (signature && typeof signature === 'string') {
                        this.setState({signature});
                    }
                }
            };

            reader.readAsDataURL(file);
        }
    }

    render() {
        return (
            <Container>
                {this.state.loading && <LoadingSpinner />
                }
                {this.state.registrant && <RegistrantReview {...this.state.registrant!} />}
                <br/>
                <div>
                    <b>If information provided is incomplete or incorrect, this e-submission may be denied and you may be non-compliant. Allow 3 business days for processing and then check your compliance status at (Wisconsin Sex Offender Registry).</b>
                </div>
                <br/>
                <Form onSubmit={this.onSubmit}>
                    <div>
                        <Row>
                            <h2>Agreement</h2>
                            <Form.Label>In accordance with Wisconsin Statute 301.45, I am registering the information as true and accurate.  I understand that I am legally required to supply this information annually or every 90 days as prescribed by law.  I also understand I must report any changes in information either before the change or no later than 10 days following any change in residence, employment, volunteer work, email addresses, internet identifiers or school enrollment. I understand that failure to comply, or providing false information, may be cause for revocation and/or further criminal prosecution. I also understand this information will be used for purposes established by law.</Form.Label>
                            <Form.Label>Failure to provide updated information or refusal to sign this form is not a defense for not complying with the WI-DOC SOR registry requirements.</Form.Label>
                        </Row>
                        {this.state.signature &&
                        <Row>
                            <img
                                src={`${this.state.signature}`}
                                alt='Your signature'
                            />
                        </Row>
                        }
                        <br/>
                    </div>
                    <Form.Row>
                        <Col xs="auto">
                            <Button variant="secondary" onClick={() => this.props.onButtonClick(-1)}>Back</Button>
                        </Col>
                        <Col>
                            <SignatureModal
                                onSignatureAccept={(signature => this.setState({signature}))}
                                buttonProps={{block: true}}
                                signature={this.state.signature}
                            />
                        </Col>
                        {(window as any)._env_?.allowSignatureFile &&
                        <Col xs="auto">
                            <Form.Group>
                                <Form.File
                                    label='Or Upload an Image of Your Signature'
                                    onChange={this.handleFileUpload}
                                />
                            </Form.Group>
                        </Col>
                        }
                        {this.state.signature &&
                        <Col>
                            <Button type="submit" block>Submit</Button>
                        </Col>
                        }
                    </Form.Row>
                </Form>
                <Modal show={this.state.agreed} centered backdrop="static">
                    <Modal.Header>
                        <Modal.Title>Success!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>You have successfully submitted your registration letter. You do not need to send in your paper letter.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={Session.getInstance().logout}>Logout</Button>
                    </Modal.Footer>
                </Modal>
                <ErrorModal error={this.state.error} additionalButtons={this.state.errorButtons} />
            </Container>
        );
    }
}

const DisplayIdentification: React.FunctionComponent<Identification> =
    ({weight, height, hairColor, eyeColor, driversLicense, stateIssued}) => {
    return (
        <div>
            <Row><h5>Identification</h5></Row>
                {weight && <Row><Col>{`Weight: ${weight} lbs`}</Col></Row>}
                {height && <Row><Col>{`Height: ${height}`}</Col></Row>}
                {hairColor && <Row><Col>{`Hair Color: ${hairColor}`}</Col></Row>}
                {eyeColor && <Row><Col>{`Eye Color: ${eyeColor}`}</Col></Row>}
                {driversLicense && <Row><Col>{`Driver's License: ${driversLicense}`}</Col></Row>}
                    {stateIssued && <Row><Col>{`License State: ${stateIssued}`}</Col></Row>}
        </div>
    );
}

const RegistrantReview = ({identification, aliases = [], markings = [], phones = [],
                          addresses = [], employers = [], schools = [],
                          emergencyContacts = [], vehicles = [],
                          internetUsages = []}: Registrant) => {

    return (
        <Container>
            <Row>
                <h2>Review</h2>
            </Row>
            <Row>
                <Col>Review the information below and sign the agreement.</Col>
            </Row>
            <br />
            {!identification && phones.length === 0 && addresses.length === 0 &&
            employers.length === 0 && schools.length === 0 && emergencyContacts.length === 0 &&
            vehicles.length === 0 && internetUsages.length === 0 &&
            <Row><Col>No Changes</Col></Row>
            }
            {identification && <DisplayIdentification {...identification} />}
            {aliases.length > 0 &&
            <div>
                <Row>
                    <h5>Aliases</h5>
                </Row>
                <Row>
                    <Col>
                        {aliases.map(alias => <Row key={alias.id}>{Utilities.parseName(alias)}</Row>)}
                    </Col>
                </Row>
            </div>
            }
            {markings.length > 0 &&
            <div>
                <Row>
                    <h5>Markings</h5>
                </Row>
                <Row>
                    <Col>
                        {markings.map(marking => <MarkingDisplay {...marking} key={marking.id}/>)}
                    </Col>
                </Row>
            </div>
            }
            {phones.length > 0 &&
            <div>
                <Row>
                    <h5>Phones</h5>
                </Row>
                <Row>
                    <Col>
                        {phones.map(phone => <PhoneDisplay {...phone} key={phone.id}/>)}
                    </Col>
                </Row>
            </div>
            }
            {addresses?.map(address => <AddressDisplay {...address} key={address.id} />)}
            {employers.length > 0 &&
            <div>
                <Row>
                    <h5>Employer</h5>
                </Row>
                <Row>
                    <Col>
                        {employers?.map(employer => <EmployerDisplay {...employer} key={employer.id}/>)}
                    </Col>
                </Row>
            </div>
            }
            {schools.length > 0 &&
            <div>
                <Row>
                    <h5>School</h5>
                </Row>
                <Row>
                    <Col>
                        {schools.map(school => <EmployerDisplay {...school} key={school.id}/>)}
                    </Col>
                </Row>
            </div>
            }
            {emergencyContacts.length > 0 &&
            <div>
                <Row>
                    <h5>Emergency Contact</h5>
                </Row>
                <Row>
                    <Col>
                        {emergencyContacts.map(emergencyContact => <EmergencyContactDisplay {...emergencyContact}
                                                                                            key={emergencyContact.id}/>)}
                    </Col>
                </Row>
            </div>
            }
            {vehicles.length > 0 &&
            <div>
                <Row>
                    <h5>Vehicles</h5>
                </Row>
                <Row>
                    <Col>
                        {vehicles.map(vehicle => <VehicleDisplay {...vehicle} key={vehicle.id}/>)}
                    </Col>
                </Row>
            </div>
            }
            {internetUsages.length > 0 &&
            <div>
                <Row>
                    <h5>Internet Use</h5>
                </Row>
                <Row>
                    <Col>
                        {internetUsages.map(usage => <InternetUsageDisplay {...usage} key={usage.id}/>)}
                    </Col>
                </Row>
            </div>
            }
        </Container>
    );
}
