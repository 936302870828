import React, {FormEvent, FunctionComponent, useState} from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import PreviousNextButtons, {ButtonType, PreviousNextButtonHandler} from "../Inputs/PreviousNextButtons";
import ErrorModal, {ErrorModalButtonInfo} from "../Modals/ErrorModal";
import {ColProps} from "react-bootstrap/cjs";
import Container from "react-bootstrap/Container";
import LoadingContainer from "../Utilities/LoadingContainer";
import {useEntityContext} from "../Providers/EntityProvider";

export interface SectionNextButtonHandler {
    onButtonClick: (increment: number) => void;
}


export interface SectionBaseProps extends PreviousNextButtonHandler {
    prompt: string;
    subtitle?: string;
    hasOldItem?:boolean;
    error?: any;
    additionalErrorButtons?: ErrorModalButtonInfo[];
    loading: boolean;
    originalInfoElement?: JSX.Element;
    changedInfoElement?: JSX.Element;
    originalColProps?: ColProps;
    changedColProps?: ColProps;
    onErrorModalClose?: () => void;
}

interface SubtitleMap {
    [key: string]: string
}

const SectionBase: FunctionComponent<SectionBaseProps> =
    ({prompt, subtitle, hasOldItem, error, loading, originalInfoElement,
         changedInfoElement, originalColProps, additionalErrorButtons,
         changedColProps, onButtonClick, children, onErrorModalClose}) => {
    const formRef = React.createRef<HTMLFormElement>();
    const [validated, setValidated] = useState(false);
    const {name} = useEntityContext();

    const messageDictionary: SubtitleMap = {};
    messageDictionary["school"]=hasOldItem ? "Click yes if information is correct. If information is not correct check no and go to next column.": "Click yes if information is correct or no if information is not correct. Click add to report a new or additional school. Click next when you are done reporting your information or if you are not attending any school at this time.";
    messageDictionary["employer"]=hasOldItem ? "Click yes if information is correct. If information is not correct check no and go to next column.": "Click yes if information is correct or no if information is not correct. Click add to report a new or additional employer or volunteer organization. Click next when you are done reporting your information or if you are unemployed and not volunteering.";

    const handleClick = (button: ButtonType) => {
        if (formRef.current?.checkValidity()) {
            onButtonClick(button);
        }
        else {
            setValidated(true);
        }
    };
    const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        handleClick(ButtonType.NEXT);
    };
    return (
        <div>
            <Col className="text-center">
                <h4>Update Information on Record</h4>
                <p>{messageDictionary[name] || subtitle}</p>
            </Col>
            <Container className="mt-4">
                <LoadingContainer loading={loading}>
                    <Form ref={formRef} onSubmit={handleFormSubmit} validated={validated} noValidate>
                        <Form.Row>
                            <Col {...originalColProps}>
                                {originalInfoElement}
                            </Col>
                            <Col {...changedColProps}>
                                <Form.Label column="lg">{prompt}</Form.Label>
                                {children &&
                                <Form.Group>
                                    {children}
                                </Form.Group>
                                }
                                {changedInfoElement}
                            </Col>
                        </Form.Row>
                        <ErrorModal error={error} additionalButtons={additionalErrorButtons} onClose={onErrorModalClose}/>
                        <PreviousNextButtons onButtonClick={handleClick} buttonsDisabled={loading} />
                    </Form>
                </LoadingContainer>
            </Container>
        </div>
    );
};
SectionBase.defaultProps = {
    originalColProps: { md: 6 },
    changedColProps:  { md: 6 }
}
export default SectionBase;