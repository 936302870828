import React, {SyntheticEvent} from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Session from "../Utilities/Session";


interface LogoutPageProps {
}
interface LogoutPageState {
    count: number;
}
export default class LogoutPage extends React.Component<LogoutPageProps, LogoutPageState> {
    state = { count: 10 };
    goToLoginPageTimeout?: NodeJS.Timeout;

    componentDidMount() {
        Session.getInstance().logout();
        this.goToLoginPageTimeout = setInterval(() => {
            this.setState(({count}) => {
                if (count <= 1) {
                    this.clearTimeout();
                    window.location.pathname = "/";
                }

                return {count: Math.max(count - 1, 0)};
            })
        }, 1000);
    }

    componentWillUnmount() {
        this.clearTimeout();
    }

    clearTimeout = () => {
        if (this.goToLoginPageTimeout) {
            clearTimeout(this.goToLoginPageTimeout);
            this.goToLoginPageTimeout = undefined;
        }
    }

    handleButtonClick = (event: SyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();
        this.clearTimeout();
        window.location.pathname = "/";
    }

    render() {
        return (
            <Container className="text-center">
                <h4>You have been logged out.</h4>
                <Button variant="link" onClick={this.handleButtonClick}>Click here to login.</Button>
                <p>You will automatically be redirected to the login page in {this.state.count}.</p>
            </Container>
        );
    }
}