import React from "react";
import Modal from "react-bootstrap/Modal";

interface FaqsProps {
    open: boolean;
    onClose: () => void
}

const Faqs: React.FunctionComponent<FaqsProps> = ({open, onClose}) => {
    return (
        <Modal
            show={open}
            centered
            size="lg"
            onHide={onClose}
        >
            <Modal.Title style={{backgroundColor: '#f1f1f1', paddingLeft: '15px'}}>FAQs</Modal.Title>
            <Modal.Body>
                <iframe  src={"./sor_public_faqs_2024-08-15.pdf"} frameBorder="0" scrolling="auto" height="450" width="100%" />
            </Modal.Body>
        </Modal>
    )
}

export default Faqs;