import IdentificationInformation from "../Sections/IdentificationInformation";
import AliasesSection from "../Sections/AliasesSection";
import MarkingsSection from "../Sections/MarkingsSection";
import PhoneSection from "../Sections/PhoneSection";
import AddressSection from "../Sections/AddressSection";
import EmployerSection from "../Sections/EmployerSection";
import SchoolSection from "../Sections/SchoolSection";
import EmergencyContactsSection from "../Sections/EmergencyContactsSection";
import VehicleSection from "../Sections/VehicleSection";
import InternetSection from "../Sections/InternetSection";
import ReviewPage from "../Sections/ReviewPage";

export interface Step {
    title: string;
    component: any;
    icon: string;
    onClick?: (...params: any) => void;
}

const IdentificationStep:Step = {
    title: "Identification",
    icon: "tag.svg",
    component: IdentificationInformation,
};
const AliasStep:Step = {
    title: "Aliases",
    icon: "alias.svg",
    component: AliasesSection
};
const MarkingStep:Step = {
    title: "Markings",
    icon: "marking.svg",
    component: MarkingsSection
};
const PhoneStep:Step = {
    title: "Phones",
    icon: "phone.svg",
    component: PhoneSection
};
const AddressStep:Step = {
    title: "Address",
    icon: "house.svg",
    component: AddressSection
};
const EmployerStep:Step = {
    title: "Employer",
    icon: "door.svg",
    component: EmployerSection
};
const SchoolStep:Step = {
    title: "School",
    icon: "book.svg",
    component: SchoolSection
};
const CollateralStep:Step = {
    title: "Emergency Contact",
    icon: "bookmarkplus.svg",
    component: EmergencyContactsSection
}
const VehicleStep:Step = {
    title: "Vehicle",
    icon: "vehicle.svg",
    component: VehicleSection
};
const InternetStep:Step = {
    title: "Internet Use",
    icon: "internet.svg",
    component: InternetSection
};
const ReviewStep:Step = {
    title: "Review",
    icon: "clipboard.svg",
    component: ReviewPage
};

const StepMap: Record<string, Step> = {
    identification: IdentificationStep,
    alias: AliasStep,
    marking: MarkingStep,
    phone: PhoneStep,
    address: AddressStep,
    employer: EmployerStep,
    school: SchoolStep,
    emergencycontact: CollateralStep,
    vehicle: VehicleStep,
    internet: InternetStep,
    review: ReviewStep
};

export const getSteps = ():Step[] => {
    let ret:Step[] = [];
    const steps:string = (window as any)._env_?.STEPS ?? "";
    const stepArray = steps.toLowerCase().split(",");
    for (const key of stepArray) {
        let step = StepMap[key];
        if (step) {
            ret.push(step);
        }
    }

    return ret;
}
