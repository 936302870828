import React from "react";
import {EmergencyContact, LookupValue} from "../generated";
import Form from "react-bootstrap/Form";
import EntityInput from "./EntityInput";
import Col from "react-bootstrap/cjs/Col";
import {ManagedInput} from "./ManagedInput";
import TextInput from "./TextInput";

interface EmergencyContactInputProps {
    contact: EmergencyContact;
    updateItem: (item: Partial<EmergencyContact>) => void;
    nameSuffixes: LookupValue[];
}
const EmergencyContactInput: React.FunctionComponent<EmergencyContactInputProps> = ({contact, updateItem, nameSuffixes}) => {

    return (
        <Form.Group>
            <EntityInput
                noCounty noGeocoder
                entityNamePrompt={
                    <div>
                        <Form.Label>Contact Name</Form.Label>
                        <Form.Row>
                            <Col sm={5} md={4}>
                                <ManagedInput
                                    placeholder="Last"
                                    // ariaLabel="Enter your last name" TODO: aria?
                                    onChange={lastName => updateItem({lastName})}
                                    value={contact.lastName}
                                    regexp={/.+/}
                                    required
                                    maxLength={50}
                                />
                                <Form.Control.Feedback type="invalid">You must enter a last name.</Form.Control.Feedback>
                            </Col>
                            <Col sm={5} md={4}>
                                <ManagedInput
                                    placeholder="First"
                                    // aria-label="Enter your first name"
                                    onChange={firstName => updateItem({firstName})}
                                    value={contact.firstName}
                                    regexp={/.+/}
                                    required
                                    maxLength={50}
                                />
                                <Form.Control.Feedback type="invalid">You must enter a first name.</Form.Control.Feedback>
                            </Col>
                            <Col sm={2}>
                                <ManagedInput
                                    placeholder="Middle"
                                    aria-label="Enter the middle name or initial"
                                    onChange={middleName => updateItem({middleName})}
                                    value={contact.middleName}
                                    regexp={/.+/}
                                    maxLength={50}
                                />
                            </Col>
                            <Col sm={2}>
                                <Form.Control
                                    as="select"
                                    value={contact.suffixName}
                                    onChange={event => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        if (event.currentTarget.value === '---') {
                                            updateItem({suffixName: undefined});
                                        }
                                        else {
                                            updateItem({suffixName: event.currentTarget.value})
                                        }
                                    }}
                                    custom
                                >
                                    <option>---</option>
                                    {nameSuffixes.map( (option: LookupValue) => {
                                        return <option key={option.id}>{option.value}</option>;
                                    })}
                                </Form.Control>
                            </Col>
                        </Form.Row>
                    </div>
                }
                entityPhonePrompt="Contact Phone"
                entity={contact}
                onChange={entity => updateItem(entity)}
                requireAddress={false}
            >
                <TextInput
                    prompt="Relationship to You"
                    value={contact.relationship}
                    onChange={relationship => updateItem({relationship})}
                    id="relationship"
                    errormessage="You must enter their relationship to you."
                    maxLength={100}
                />
            </EntityInput>
        </Form.Group>
    );
}
export default EmergencyContactInput;