import React from "react";
import {ColProps} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

export interface InputLabelAndFeedbackProps {
    colProps?: ColProps;
    prompt: string;
    errormessage?: string;
    id: string;
}

const InputLabelAndFeedback: React.FunctionComponent<InputLabelAndFeedbackProps> =
    ({colProps, id, prompt, children, errormessage}) => {

    return (
        <Col {...colProps}>
                <Form.Label id={id}>{prompt}</Form.Label>
                {children}
                {errormessage &&
                <Form.Control.Feedback type="invalid">{errormessage}</Form.Control.Feedback>}
        </Col>
    );
}
export default InputLabelAndFeedback;