import React from "react";
import Session from "../Utilities/Session";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface TokenTimerProps {
    expirationTime: number;
}
interface TokenTimerState {
    idleTime: number;
}
const IDLE_WARNING_MS = 20000;

export default class TokenTimer extends React.Component<TokenTimerProps, TokenTimerState> {
    state = {
        idleTime: -1
    }

    countDownTimeout?: NodeJS.Timeout;
    tokenTimeout?: NodeJS.Timeout;

    componentDidMount() {
        this.checkAndStartTokenTimer();
    }

    componentWillUnmount() {
        this.clearTimeouts();
    }

    clearTimeouts = () => {
        if (this.countDownTimeout) {
            clearInterval(this.countDownTimeout);
            this.countDownTimeout = undefined;
        }
        if (this.tokenTimeout) {
            clearTimeout(this.tokenTimeout);
            this.tokenTimeout = undefined;
        }
    }

    logout = () => {
        Session.getInstance().logout();
    }
    startCountDown = (remainingTime: number) => {
        this.setState({idleTime: Math.floor(remainingTime / 1000)})
        this.countDownTimeout = setInterval(() => {
            this.setState(({idleTime}) => {
                if (idleTime <= 1) {
                    this.clearTimeouts();
                    this.logout();
                }

                return {idleTime: Math.max(idleTime - 1, 0)};
            })
        }, 1000);
    }

    checkAndStartTokenTimer = () => {
        this.clearTimeouts();
        this.setState({idleTime: -1});
        let remainingTime = this.props.expirationTime - Date.now();

        if (remainingTime <= 0) {
            this.logout();
        }
        else {
            if (remainingTime < IDLE_WARNING_MS) {
                this.startCountDown(remainingTime)
            }
            else {
                this.tokenTimeout = setTimeout(this.checkAndStartTokenTimer, remainingTime - IDLE_WARNING_MS);
            }
        }
    }

    handleLogout = () => {
        Session.getInstance().logout();
    }

    handleStayLoggedIn = () => {
        Session.getInstance().refreshToken(loggedIn => {
            if (loggedIn) {
                this.checkAndStartTokenTimer();
            }
            else {
                this.handleLogout();
            }
        })
    }


    render() {
        return (
            <Modal
                show={this.state.idleTime > 0}
                backdrop="static"
                centered
            >
                <Modal.Header>
                    <Modal.Title>Your session is about to expire!</Modal.Title>
                </Modal.Header>
                <Modal.Body>You will be logged out in {this.state.idleTime} seconds unless you click 'Stay Logged In".</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleStayLoggedIn}>Stay Logged In</Button>
                    <Button variant="secondary" onClick={this.handleLogout}>Log Out Now</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}