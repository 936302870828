import React, {SyntheticEvent} from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";

export enum ButtonType {
    PREVIOUS = -1,
    NEXT = 1
}
export interface PreviousNextButtonHandler {
    onButtonClick: (button: ButtonType) => void;
}
export interface PreviousNextButtonsProps extends PreviousNextButtonHandler {
    allowPreviousButton: boolean;
    buttonsDisabled: boolean;
}
interface PreviousNextButtonsState {
}
export default class PreviousNextButtons extends React.Component<PreviousNextButtonsProps, PreviousNextButtonsState> {
    static defaultProps = {
        allowPreviousButton: true,
        buttonsDisabled: false
    }

    handleButtonClick = (button: ButtonType) => (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.onButtonClick(button);
    }
    render() {
        return (
            <div>
                <br/>
                <Form.Row>
                    <Col xs={6}>
                        {this.props.allowPreviousButton &&
                        <Button
                            variant="secondary"
                            onClick={this.handleButtonClick(ButtonType.PREVIOUS)}
                            disabled={this.props.buttonsDisabled}
                            block
                        >Back</Button>
                        }
                    </Col>
                    <Col xs={6}>
                        <Button
                            variant="primary"
                            disabled={this.props.buttonsDisabled}
                            onClick={this.handleButtonClick(ButtonType.NEXT)}
                            block
                        >Next</Button>
                    </Col>
                </Form.Row>
            </div>
        );
    }
}