import React from "react";
import {Entity} from "../Utilities/Models";
import AddressDisplay from "./AddressDisplay";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";

interface EntityDisplayProps extends Entity {
    isCurrent?: boolean;
}
const EntityDisplay: React.FunctionComponent<EntityDisplayProps> = ({isCurrent, children, ...props}) => {
    return (
        <div style={isCurrent !== false ? undefined : {textDecoration: 'line-through'}}>
            <Row>
                <Col>{props.name}</Col>
            </Row>
            <AddressDisplay {...props} />
            <Row>
                <Col xs={5} sm={5} md={6} lg={4}>Phone:</Col>
                <Col>{props.phoneNumber}</Col>
            </Row>
            {children}
        </div>
    );
}
export default EntityDisplay;