import React from "react";
import {SectionNextButtonHandler} from "./SectionBase";
import EntityInput from "../Inputs/EntityInput";
import DatePickerWrapper from "../Inputs/DatePickerWrapper";
import {OffenderSchool, School} from "../generated";
import EmployerDisplay from "../DisplayOnly/EmployerDisplay";
import {SingleUpdateSectionStringsFromItemName} from "./ListSectionBase";
import YesNoInput from "../Inputs/YesNoInput";
import { Form } from "react-bootstrap";
import BaseEndDateSection from "./BaseEndDateSection";
import {EntityProvider} from "../Providers/EntityProvider";

const SchoolSection: React.FunctionComponent<SectionNextButtonHandler> = ({onButtonClick}) => {
    const displaySchool = (school: School): JSX.Element => { //TODO: how to update school? , updateItem:UpdateFunction<School>
        return (
            <div>
                <EmployerDisplay {...school} />
            </div>
        );
    };

    const displaySchoolInput = (school: School, updateItem:(item: Partial<School>) => void): JSX.Element => {
        return (
            <Form.Group>
                <Form.Row>
                    <YesNoInput
                        value={school.online}
                        onChange={online => updateItem({online})}
                        id="online"
                        question="Is this an online school?"
                        feedback="You must answer this question."
                    />
                </Form.Row>
                <EntityInput
                    entityNamePrompt="Name of School I am now attending"
                    entityPhonePrompt="School Phone"
                    entity={school}
                    onChange={entity => updateItem(entity)}
                    collapseAddressSection={school.online}
                >
                    <DatePickerWrapper
                        prompt="Start Date"
                        value={school.startDate}
                        onChange={startDate => updateItem({startDate})}
                        errormessage="Please enter a start date."
                    />
                </EntityInput>
            </Form.Group>
        );
    };

    return (
        <EntityProvider entityName="school">
        <BaseEndDateSection<School, OffenderSchool>
            strings={SingleUpdateSectionStringsFromItemName('School')}
            onItemSubmit={onButtonClick}
            displayItem={displaySchool}
            displayItemInput={displaySchoolInput}
            apiUrl='/school'
            endDatePromptTitle='When did your enrollment at this school end?'
        >
        </BaseEndDateSection>
        </EntityProvider>
    );
}

export default SchoolSection;