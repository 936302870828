import React from "react";
import Collapse from "react-bootstrap/cjs/Collapse";
import Col from "react-bootstrap/Col";
import {Form} from "react-bootstrap";

interface HelpTextProps {
    show?: boolean;
}
const HelpText: React.FunctionComponent<HelpTextProps> = ({children, show}) => {
    return (
        <Collapse in={show}>
            <Col xs={24}>
                <Form.Text muted>
                    {children}
                </Form.Text>
            </Col>
        </Collapse>
    );
}
export default HelpText;