import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Address, OffenderAddress} from "../generated";
import Utilities from "../Utilities/Utilities";

const AddressDisplay: React.FunctionComponent<Address | OffenderAddress> = ({addressType, street, street2, city, outOfStateCity, state, zip, facility, homeless, county, ...props}) => {
    const current = (props as Address).current ?? (props as OffenderAddress).updateEntity?.current ?? true;
    let stateString =  "";
    if (state) {
        stateString = ", ";
        if (state.id === 57) { //Out of country
            stateString += props.country?.description;
        }
        else {
            stateString += state?.name;
        }
    }
    return (
        <div style={current ? undefined : {textDecoration: 'line-through'}}>
            {addressType &&
            <Row>
                <h5>{addressType.description} Address</h5>
            </Row>
            }
            {homeless ? <Row><Col>Homeless {zip} {county?.value}</Col></Row> : (
            <Row>
                {(() => {
                    if (facility) {
                        return (
                            <Col>{facility.name}</Col>
                        );
                    }
                    else {
                        return (
                            <Col>
                                {street &&
                                <Row>
                                    <Col>{street}</Col>
                                </Row>}
                                {street2 &&
                                <Row>
                                    <Col>{street2}</Col>
                                </Row>
                                }
                                {street &&
                                <Row>
                                    <Col>{Utilities.displayCity({outOfStateCity, city})}{stateString} {zip}</Col>
                                </Row>
                                }
                            </Col>
                        );
                    }
                })()}
            </Row>
            )}
        </div>
    );
}

export default AddressDisplay;