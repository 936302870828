import Modal, {ModalProps} from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const LoadingSpinner = (props: ModalProps) => {
    return (
        <Modal
            show={true}
            {...props}
            aria-labelledby="loading-indicator"
            centered
            size="sm"
        >
            <Modal.Body>
                <Row className="justify-content-center">
                    <Col xs="auto">
                        <Spinner animation="border" role="status">
                            <span className="sr-only" id="loading-indicator">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};
export default LoadingSpinner;