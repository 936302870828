import React from "react";
import {SectionNextButtonHandler} from "./SectionBase";
import AddOnlyListSection from "./AddOnlyListSection";
import MarkingInput from "../Inputs/MarkingInput";
import Utilities, {CancelablePromise} from "../Utilities/Utilities";
import {Marking, MarkingType} from "../generated";
import MarkingDisplay from "../DisplayOnly/MarkingDisplay";

interface MarkingsSectionState {
    markingTypes: MarkingType[];
    markingTypeMap: Record<string, MarkingType>;
    error?: any;
}
class MarkingsSection extends React.Component<SectionNextButtonHandler, MarkingsSectionState> {
    state = {
        markingTypes: [] as MarkingType[],
        markingTypeMap: {} as Record<string, MarkingType>,
        error: undefined
    }
    request?: CancelablePromise<any> = undefined;
    componentWillUnmount() {
        this.request?.cancel();
    }

    componentDidMount() {
        this.request = Utilities.fetchJSON<MarkingType[]>({url: '/marking/types'});
        this.request.promise.then(markingTypes => {
            this.setState({markingTypes});
            let markingTypeMap = {} as Record<string, MarkingType>;
            for (const markingType of markingTypes) {
                markingTypeMap[markingType.value] = markingType;
            }
            this.setState({markingTypeMap});
            this.request = undefined;
        }).catch(error => {
            if (!error.isCanceled) {
                this.setState({error});
            }
            this.request = undefined;
        })
    }
    render() {
        return (
            <AddOnlyListSection<Marking>
                url='/marking'
                prompt="Add Additional Scars, Marks, or Tattoos"
                readonlyHeader="Current Scars, Marks, or Tattoos"
                addNewItemPrompt="Add Scar, Mark, or Tattoo"
                onButtonClick={this.props.onButtonClick}
                displayReadonlyItem={marking => <MarkingDisplay {...marking}/>}
                displayItem={(item, updateItem) =>
                    <MarkingInput
                        marking={item}
                        updateItem={updateItem}
                        markingTypes={this.state.markingTypes}
                        markingType={this.state.markingTypeMap[item.type ?? ""]}
                    />}
                originalColProps={{md: 6}}
                changedColProps={{md: 6}}
            />
        );
    }
}
export default MarkingsSection;