import React from "react";
import {EntitiesResponse, Identifiable} from "../Utilities/Models";
import Utilities, {CancelablePromise} from "../Utilities/Utilities";
import SectionBase, {SectionNextButtonHandler} from "./SectionBase";
import {Form} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ListInput from "../Inputs/ListInput";
import {ColProps} from "react-bootstrap/cjs";

interface AddOnlyListSectionProps<T> extends SectionNextButtonHandler {
    url: string;
    prompt: string;
    readonlyHeader: string;
    addNewItemPrompt: string;
    displayReadonlyItem: (item: T) => JSX.Element;
    displayItem: (item: T, updateItem:(item: Partial<T>) => void) => JSX.Element;
    originalColProps?: ColProps;
    changedColProps?: ColProps;
}
interface AddOnlyListSectionState<T> extends EntitiesResponse<T, T> {
    loading: boolean;
    error?: any;
    retryFunction: () => void;
}

class AddOnlyListSection <T extends Identifiable> extends React.Component<AddOnlyListSectionProps<T>, AddOnlyListSectionState<T>> {
    static defaultProps = {
        originalColProps: {md: 'auto'},
        changedColProps: {}
    }

    state = {
        loading: true,
        error: undefined,
        newItems: undefined as undefined | T[],
        previousItems: undefined as undefined | T[],
        retryFunction: () => {}
    }
    request?: CancelablePromise<any> = undefined;
    componentWillUnmount() {
        this.request?.cancel();
    }

    componentDidMount() {
        this.loadData();
    }

    sortItem = (a: any, b:any):number => {
        if (!a.hasOwnProperty("website") || !b.hasOwnProperty("website")) {
            return 0;
        }

        const varA = a.website.toUpperCase();
        const varB = b.website.toUpperCase();

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return comparison;
    };

    loadData = () => {
        this.request = Utilities.fetchJSON<EntitiesResponse<T, T>>({url: this.props.url});
        this.request.promise.then(response => {
            this.setState({...response, loading: false});
            this.request = undefined;
        }).catch(error => {
            if (!error.isCanceled) {
                this.setState({
                    error,
                    retryFunction: () => {
                        this.setState({
                            error: undefined,
                            loading: true
                        });
                        this.loadData();
                    },
                    loading: false
                });
            }
            this.request = undefined;
        })
    }

    handleSubmit = (increment: number) => {
        this.setState({loading: true});
        this.request = Utilities.fetchJSON<T[]>({
            url: this.props.url,
            body: JSON.stringify(this.state.newItems)
        });
        this.request.promise.then(response => {
            this.props.onButtonClick(increment);
            this.request = undefined;
        }).catch((error: any) => {
            if (!error.isCanceled) {
                this.setState({
                    error,
                    retryFunction: () => {
                        this.setState({error: undefined});
                        this.handleSubmit(increment);
                    },
                    loading: false
                });
                this.request = undefined;
            }
        });
    }
    render() {
        return (
            <SectionBase
                prompt={this.props.prompt}
                loading={this.state.loading}
                onButtonClick={this.handleSubmit}
                error={this.state.error}
                additionalErrorButtons={[{
                    text: 'Retry',
                    variant: 'secondary',
                    onClick: this.state.retryFunction
                }]}
                originalColProps={this.props.originalColProps}
                changedColProps={this.props.changedColProps}
                originalInfoElement={(this.state.previousItems?.length ?? 0) === 0 ? undefined :
                    <Card>
                        <Card.Header>
                            <Form.Label>{this.props.readonlyHeader}</Form.Label>
                        </Card.Header>
                        <Card.Body>
                            {this.state.previousItems?.sort(this.sortItem).map((item, index) => {
                                return (
                                    <div key={index}>{this.props.displayReadonlyItem(item)}</div>
                                );
                            })}
                        </Card.Body>
                    </Card>
                }
                changedInfoElement={
                    <ListInput<T>
                        items={this.state.newItems ?? []}
                        onItemsChange={newItems => this.setState({newItems})}
                        addNewItemPrompt={this.props.addNewItemPrompt}
                        display={this.props.displayItem}
                    />
                }
            >
            </SectionBase>
        );
    }
}
export default AddOnlyListSection;
