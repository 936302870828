import React from "react";
import {Form} from "react-bootstrap";
import DropDownInput, {UseIdDropDownProps, UseValueDropDownProps} from "./DropDownInput";
import Utilities, {CancelablePromise} from "../Utilities/Utilities";

export interface CategoryValue {
    id?: number;
    value?: string;
}

type CategoryValuePickerProps<T extends CategoryValue> = (UseIdDropDownProps<T> | UseValueDropDownProps<T>) & {
    apiUrl: string;
    hidden?: boolean;
}
interface CategoryValuePickerState<T extends CategoryValue> {
    categories: T[];
    error: any | null;
}
class CategoryValuePicker<T extends CategoryValue> extends React.Component<CategoryValuePickerProps<T>, CategoryValuePickerState<T>> {
    static defaultProps = {
        displayKey: 'value',
        options: [],
        ...DropDownInput.defaultProps,
    }
    state = {
        categories: [],
        error: null
    }
    request?: CancelablePromise<any> = undefined;
    componentWillUnmount() {
        this.request?.cancel();
    }

    componentDidMount() {
        this.requestData();
    }

    componentDidUpdate(prevProps: Readonly<CategoryValuePickerProps<T>>, prevState: Readonly<CategoryValuePickerState<T>>, snapshot?: any) {
        if (prevProps.apiUrl !== this.props.apiUrl) {
            this.requestData();
        }
    }

    requestData = () => {
        this.request = Utilities.fetchJSON<T[]>({url: this.props.apiUrl, useCache: true});
        this.request.promise
            .then(categories => {
                this.setState({categories});
                this.request = undefined;
            })
            .catch((error: any) => {
                if (!error.isCanceled) {
                    this.setState({error});
                    this.request = undefined;
                }
            })
    }

    render() {
        const {apiUrl, hidden, ...otherProps} = this.props;
        if (this.state.error) {
            return (
                <Form.Label>Error loading {apiUrl}</Form.Label>
            )
        }
        let ret = (
            <DropDownInput
                {...otherProps}
                options={this.state.categories}
            />
        );
        if (hidden) {
            ret = (
                <div style={{display: "none"}}>
                    {ret}
                </div>
            );
        }
        return ret;
    }
}
export default CategoryValuePicker;