import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";

interface AppBannerProps {
    isLoggedIn: boolean;
}

const AppBanner: React.FunctionComponent<AppBannerProps> = ({isLoggedIn, children}) => {
    const getIsPhoneSized = () => window.innerWidth < 768;
    const [isPhoneSized, setIsPhoneSized] = React.useState(getIsPhoneSized());
    React.useEffect(() => {
        const handleWindowResize = () => {
            setIsPhoneSized(getIsPhoneSized());
        }
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        }
    });

    const subbanner = <h5>Wisconsin Department of Corrections</h5>;
    const banner = (
        <div className="banner">
            <Row className="nav-vertically-center-items">
                <div>
                    <Image src="WI_flag.png" className="banner-flag"/>
                </div>
                <Col xs={4} sm='auto'>
                    <Image src="DOCWebBanner.png" fluid/>
                </Col>
                <Col xs={8} className="banner-text">
                    <h3>Wisconsin Sex Offender Registry</h3>
                    {!isPhoneSized && subbanner}
                </Col>
            </Row>
            {isPhoneSized &&
            <Row>
                <Col>{subbanner}</Col>
            </Row>
            }
        </div>
    );

    const useBottomLayout = isPhoneSized && isLoggedIn;
    return (
        <div>
            {!useBottomLayout && banner}
            {children}
            {useBottomLayout && <br/>}
            {useBottomLayout && banner}
        </div>
    );
}
export default AppBanner;