import {BaseAddress, City, ErrorResult} from "./Models";
import {NamesDetail} from "../generated";
import {OtherCity, OutOfStateCity} from "./Locations";


export interface FetchJSONParams {
    url: string;
    body?: string;
    headers?: Record<string, string>;
    useCache?: boolean;
}
export interface CancelablePromise<T> {
    promise: Promise<T>;
    cancel: () => void;
}
export default class Utilities {
    private static _cache: Map<string, any>;
    private static getCache = ():Map<string, any> => {
        if (!Utilities._cache) {
            Utilities._cache = new Map();
        }
        return Utilities._cache;
    }

    static makeCancelable = <T>(promise: Promise<T>): CancelablePromise<T> => {
        let hasCanceled_ = false;
        const wrappedPromise = new Promise<T>((resolve, reject) => {
            promise.then(value => {
                if (hasCanceled_) {
                    reject({isCanceled: true});
                }
                else {
                    resolve(value);
                }
            }).catch(error => {
                if (hasCanceled_) {
                    reject({isCanceled: true});
                }
                else {
                    reject(error);
                }
            });
        });
        return {
            promise: wrappedPromise,
            cancel() {
                hasCanceled_ = true;
            }
        }
    }

    static apiUrl = (window as any)._env_?.API_URL ?? '/api';
    static fetchJSON = <T>({url, body, headers, useCache}: FetchJSONParams): CancelablePromise<T> => {
        let defaultHeaders:Record<string, string> = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        if (useCache && Utilities.getCache().has(url)) {
            return Utilities.makeCancelable(Promise.resolve(Utilities.getCache().get(url)));
        }
        return Utilities.makeCancelable(new Promise<T>((resolve, reject) => {
            fetch(Utilities.apiUrl + url, {
                method: body ? 'POST' : 'GET',
                body,
                headers: {...defaultHeaders, ...headers}
            })
                .then(result => {
                    if (!result.ok) {
                        throw new Error(`${result.status}`);
                    }
                    return result.json();
                })
                .then((json: any) => {
                    if ((json as ErrorResult).error) {
                        reject(json);
                    } else {
                        if (useCache) {
                            Utilities.getCache().set(url, json);
                        }
                        resolve(json);
                    }
                }).catch(reject);
        }));
    }

    static setCookie = (key: string, value: string, minutes?: number):number => {
        let expires = '';
        let ret = 0;
        if (minutes) {
            let date = new Date();
            date.setTime(date.getTime() + minutes * 60000);
            expires = `;expires=${date.toUTCString()}`;
            ret = date.getTime();
        }

        document.cookie = `${key}=${value}${expires};path=/`;
        return ret;
    }

    static getCookie = (key: string): string | undefined => {
        let ret: string | undefined = undefined;
        let cookies = document.cookie.split(";");
        for (const value of cookies) {
            const values = value.split("=");
            const first = values[0].trim();
            if (first === key && values.length > 1) {
                const second = values[1];
                if (second.length > 0) {
                    ret = second;
                }
                break;
            }
        }
        return ret;
    }

    static deleteCookie = (key: string): boolean => {
        const ret:boolean = Utilities.getCookie(key) !== undefined;

        if (ret) {
            Utilities.setCookie(key, "", -1);
        }

        return ret;
    }

    static extract = <T>(properties: T) => {
        return function<TActual extends T>(value: TActual){
            let result = {} as T;
            for (const property of Object.keys(properties as {}) as Array<keyof T>) {
                result[property] = value[property];
            }
            return result;
        }
    }

    static parseName = ({firstName, lastName, middleName, suffixName}: NamesDetail): string => {
        let name = lastName;
        if (firstName || middleName || suffixName) {
            name += ',';
        }
        if (firstName) {
            name += ` ${firstName}`;
        }
        if (middleName) {
            name += ` ${middleName}`;
        }
        if (suffixName) {
            name += ` ${suffixName}`;
        }
        return name;
    }

    static isObject = (object: any): boolean => object != null && typeof object === 'object';

    static deepEqual = (object1: any, object2: any, keys?:string[]): boolean => {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys ?? keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = Utilities.isObject(val1) && Utilities.isObject(val2);
            if ((areObjects && !Utilities.deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
                return false;
            }
        }
        return true;
    }

    static addressIsEditable = (address: BaseAddress) => {
        return address.latitude !== undefined && address.longitude !== undefined;
    }

    static displayCity = ({city, outOfStateCity}: City): string => {
        switch (city?.id) {
            case OutOfStateCity.id:
            case OtherCity.id:
                return outOfStateCity ?? "";
            default:
                return city?.value ?? "";
        }
    }
}
