import React from "react";
import {DOCOriginal, DOCSubmittable, EntitiesResponse} from "../Utilities/Models";
import Form from "react-bootstrap/Form";
import EntityStatusInput from "../Inputs/EntityStatusInput";
import {ButtonType} from "../Inputs/PreviousNextButtons";
import ListInput, {UpdateFunction} from "../Inputs/ListInput";
import Card from "react-bootstrap/Card";
import SectionBase from "./SectionBase";
import Utilities, {CancelablePromise} from "../Utilities/Utilities";
import {ErrorModalButtonInfo} from "../Modals/ErrorModal";

export interface ListSectionStrings {
    noOldItemsString: string;
    oldItemStatusPrompt: string;
    currentItemsListTitle: string;
    addNewItemPrompt: string;
}

//Note, if we ever need to support translation, this method will not work. For English only it's fine though
export const ListSectionStringsFromItemName = (itemName: string, itemNamePlural?:string): ListSectionStrings => {
    let plural = itemNamePlural ? itemNamePlural : `${itemName}s`;
    return {
        noOldItemsString: `No Previous ${plural} Saved`,
        oldItemStatusPrompt: `Is this ${itemName} still accurate?`,
        currentItemsListTitle: `Update Your ${plural}`,
        addNewItemPrompt: `Add ${itemName}`
    };
}
export const SingleUpdateSectionStringsFromItemName = (itemName: string): ListSectionStrings => {
    return {
        ...ListSectionStringsFromItemName(itemName, itemName),
        currentItemsListTitle: itemName
    };
}

export interface ListSectionBaseProps<T extends DOCSubmittable, I extends DOCOriginal<T>> {
    strings: ListSectionStrings;
    apiUrl: string;
    displayItem: (item: I) => JSX.Element; //TODO: rename to display old item
    displayItemInput: (item: T, updateItem:UpdateFunction<T>) => JSX.Element;
    onOldItemCurrentChange: (current: boolean, updateItem: UpdateFunction<T>, item: I) => void;
    onItemSubmit: (buttonIncrement:number) => void;
    requiredItemPrompt?: string;
    getItemIsCurrent: (item: T) => boolean | undefined;
    customValidator?: (items: (T|I)[]) => string | undefined;
    maxItems?: number;
}

interface ListSectionBaseState<T extends DOCSubmittable, I extends DOCOriginal<T>> {
    oldItems: I[];
    newItems: T[];
    showRequiredItemMessage: boolean;
    loading: boolean;
    error?: any;
    errorButtons?: ErrorModalButtonInfo[];
}

export default class ListSectionBase<T extends DOCSubmittable, I extends DOCOriginal<T>> extends React.Component<ListSectionBaseProps<T, I>, ListSectionBaseState<T, I>> {
    static defaultProps = {
        getItemIsCurrent: (item: { current: boolean | undefined; }) => item.current
    }
    state = {
        oldItems: [] as I[],
        newItems: [] as T[],
        showRequiredItemMessage: false,
        loading: true,
        error: undefined,
        errorButtons: undefined
    }

    request?: CancelablePromise<any> = undefined;

    componentWillUnmount() {
        this.request?.cancel();
    }

    componentDidMount() {
        this.requestData();
    }
    requestData = () => {
        this.request = Utilities.fetchJSON<EntitiesResponse<T, I>>({
            url: this.props.apiUrl,
        });
        this.request.promise.then(response => {
            this.setState({
                newItems: response.newItems,
                oldItems: response.previousItems,
                loading: false
            });
            this.request = undefined;
        }).catch((error: any) => {
            if (!error.isCanceled) {
                this.setState({
                    error,
                    errorButtons: [this.retryButton],
                    loading: false
                });
                this.request = undefined;
            }
        });
    }

    retryButton: ErrorModalButtonInfo = {
        text: 'Retry',
        variant: 'secondary',
        onClick: () => {
            this.setState({loading: true, error: undefined, errorButtons: undefined});
            this.requestData();
        }
    };

    handleSubmit = (items: T[], increment: number) => {
        this.setState({loading: true, errorButtons: undefined});
        this.request = Utilities.fetchJSON<T[]>({
            url: this.props.apiUrl,
            body: JSON.stringify(items)
        });
        this.request.promise.then(() => {
            this.props.onItemSubmit(increment);
            this.request = undefined;
        }).catch((error: any) => {
            if (!error.isCanceled) {
                this.setState({
                    error
                });
                this.request = undefined;
            }
        });
    }

    updateOldItem = (oldItem: I, index: number) => (updates: Partial<T>) => {
        const updateEntity = {...(oldItem.updateEntity ? oldItem.updateEntity : {...oldItem, id: undefined}), ...updates} as T;
        const newOldItem = {...oldItem, updateEntity} as I;
        let oldItems = [...this.state.oldItems];
        oldItems.splice(index, 1, newOldItem);
        this.setState({oldItems});
    }

    createNewItem = ():T => {
        return { current: true } as T;
    }

    activeOldItemsFilter = (oldItem: I): boolean => this.props.getItemIsCurrent(oldItem.updateEntity ?? {} as T) === true;
    changedOldItemsFilter = (oldItem: I): boolean => !!oldItem.updateEntity;

    getActiveOldItems = ():I[] => this.state.oldItems.filter(this.activeOldItemsFilter);
    getOldItemsWithUpdates = ():I[] => this.state.oldItems.filter(this.changedOldItemsFilter);
    getOldItemUpdates = ():T[] => {
        return this.getOldItemsWithUpdates().map<T>(o => {
            return {...o.updateEntity, offenderEntity: o} as unknown as T;
        });
    }
    getAllSubmittableItems = ():T[] => [...this.state.newItems, ...this.getOldItemUpdates()];
    getAllActiveItems = ():(I|T)[] => [...this.state.newItems, ...this.getActiveOldItems()];


    handleNextButtonClick = (button: ButtonType) => {
        if (this.props.requiredItemPrompt) {
            if (this.state.newItems.length === 0 &&
                this.getActiveOldItems().length === 0) {
                this.setState({newItems: [this.createNewItem()], showRequiredItemMessage: true});
                return;
            } else {
                this.setState({showRequiredItemMessage: false});
            }
        }

        this.handleSubmit(this.getAllSubmittableItems(), button);
    }

    handleNewItemsChange = (newItems: T[]) => {
        let showRequiredItemMessage = false;
        if (this.props.requiredItemPrompt && newItems.length === 0) {
            showRequiredItemMessage = this.state.oldItems.filter(oldItem => oldItem.updateEntity !== undefined).length === 0;

            if (showRequiredItemMessage) {
                newItems.push(this.createNewItem());
            }
        }

        this.setState({newItems, showRequiredItemMessage});
    }

    getItemCurrentValue = (item?: T): boolean | undefined => {
        if (!item) {
            return undefined;
        }
        return this.props.getItemIsCurrent(item);
    }

    render() {
        const validateMessage = this.props.customValidator?.(this.getAllActiveItems());
        return (
            <SectionBase
                prompt={this.props.strings.currentItemsListTitle}
                subtitle={`Check yes if information is correct. If information is not correct check no and go to next column. ${!this.state.oldItems.length ? "If no previous values, click on ‘Add’ to submit new information or click ‘Next’ to continue to the next page.": ""}`}
                hasOldItem={this.state.oldItems.length > 0}
                loading={this.state.loading}
                onButtonClick={this.handleNextButtonClick}
                error={this.state.error}
                onErrorModalClose={() => this.setState({loading: false, error: undefined})}
                additionalErrorButtons={this.state.errorButtons}
                originalInfoElement={
                    <div>
                        {this.state.oldItems.length === 0 &&
                        <Card>
                            <Card.Body>
                                {this.props.strings.noOldItemsString}
                            </Card.Body>
                        </Card>
                        }
                        {this.state.oldItems.map((item: I, index: number) => {
                            return (
                                <EntityStatusInput
                                    id={`${index}`}
                                    key={index}
                                    value={this.getItemCurrentValue(item.updateEntity)}
                                    onChange={value => this.props.onOldItemCurrentChange(value, this.updateOldItem(item, index), item)}
                                    question={this.props.strings.oldItemStatusPrompt}
                                >
                                    {this.props.displayItem(item)}
                                </EntityStatusInput>
                            );
                        })}
                    </div>
                }
                changedInfoElement={
                    <div>
                        <Form.Row>
                            <Form.Control isInvalid={this.state.showRequiredItemMessage} style={{display: "none"}}/>
                            <Form.Control.Feedback type="invalid">{this.props.requiredItemPrompt}</Form.Control.Feedback>
                        </Form.Row>

                        <ListInput
                            items={this.state.newItems}
                            display={this.props.displayItemInput}
                            addNewItemPrompt={this.props.strings.addNewItemPrompt}
                            onItemsChange={this.handleNewItemsChange}
                            createNewItem={this.createNewItem}
                            canAddNewItems={(!this.props.maxItems || this.props.maxItems > this.getAllActiveItems().length)}
                        />
                        {validateMessage &&
                        <Form.Row>
                            <Form.Control isInvalid style={{display: "none"}} required/>
                            <Form.Control.Feedback type="invalid">{validateMessage}</Form.Control.Feedback>
                        </Form.Row>
                        }
                    </div>
                }
            >{this.props.children}</SectionBase>
        );
    }
}