import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import {InternetUsage} from "../generated";

export const InternetUsageDisplay = (props: InternetUsage) => {
    return (
        <div>
            <Row>
                <Col sm='auto'>{props.website}</Col>
                <Col>Username: {props.alias}</Col>
            </Row>
        </div>
    );
}