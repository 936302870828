import React from "react";
import {Employer} from "../generated";
import {Form} from "react-bootstrap";
import EntityInput from "./EntityInput";
import LookupValuePicker from "./LookupValuePicker";
import DatePickerWrapper from "./DatePickerWrapper";
import TextInput from "./TextInput";
import HelpText from "./HelpText";

interface EmployerInputProps {
    employer: Employer;
    updateItem: (item: Partial<Employer>) => void;
}
const EmployerInput:React.FunctionComponent<EmployerInputProps> = ({employer, updateItem}) => {
    return (
        <Form.Group>
            <EntityInput
                entityNamePrompt="Employer, Additional Employer or Volunteer Entity"
                entityPhonePrompt="Employer Phone"
                entity={employer}
                onChange={updateItem}
            >
                <LookupValuePicker
                    useId
                    value={employer.type}
                    onChange={type => updateItem({type})}
                    id='employerType'
                    prompt='Employment Type'
                    type='Employment Type'
                    errormessage='You must select an employment type.'
                >
                </LookupValuePicker>
                <HelpText show={employer.type?.id === 444}>
                    Please enter the name and information for your temp agency and not the current location you are working at.
                </HelpText>
                <DatePickerWrapper
                    prompt="Start Date"
                    value={employer.startDate}
                    onChange={startDate => updateItem({startDate})}
                    errormessage="You must select a start date."
                />
                <TextInput
                    prompt="Duties"
                    value={employer.duties}
                    onChange={duties => updateItem({duties})}
                    id="duties"
                    errormessage="You must enter your duties as an employee."
                    maxLength={1000}
                />
            </EntityInput>
        </Form.Group>
    );
}
export default EmployerInput;