import Utilities from "./Utilities";
import {ErrorResult} from "./Models";
import {LoginResult} from "../generated";


enum AppCookies {
    CurrentStep = 'current-step',
    IsAuthenticated = 'isAuthenticated'
}

export default class Session {

    // MARK:- Singleton setup
    private static instance: Session;
    private constructor() {}
    public expirationTime: number = 0;
    public docNumber?: string = undefined;

    static getInstance(): Session {
        if (!Session.instance) {
            Session.instance = new Session();
        }
        return Session.instance;
    }

    setLastStep = (step: number) => {
        Utilities.setCookie(AppCookies.CurrentStep, step.toString(), 500);
    }

    getLastStep = (): number => {
        return this.getIntCookie(AppCookies.CurrentStep) ?? 0;
    }

    private getIntCookie = (cookie: string): number | null => {
        const ret = Utilities.getCookie(cookie);
        if (ret) {
            return Number.parseInt(ret);
        }
        return null;
    }

    isLoggedIn = (callback: (loggedIn: string | boolean, expirationTime?: number) => void) => {
        this.isLoggedInternal(`/loggedin`, callback);
    }

    isLoggedInternal = (url: string, callback: (loggedIn: string | boolean, expirationTime?: number) => void) => {
        Utilities.fetchJSON<LoginResult>({url}).promise
            .then(result => {
                if (result.tokenExpiration) {
                    this.expirationTime =  Date.parse(result.tokenExpiration);
                    this.docNumber = result.docNumber;
                    callback(true, this.expirationTime);
                }
                else {
                    callback("No token returned");
                }
            })
            .catch((error) => {
                if (error.message) {
                    callback(error.message);
                }
                else {
                    callback(false);
                }
            });
    }

    refreshToken = (callback: (loggedIn: boolean) => void) => {
        this.isLoggedInternal(`/refresh`, loggedIn => callback(loggedIn === true));
    }

    login = (docNumber: string, passcode: string, onSuccess: (expirationTime: number, status?: LoginResult.status) => void, onFail: (error: string) => void) => {
        Utilities.fetchJSON<LoginResult>({
            url:'/login',
            headers: {Authorization: `Basic ${btoa(docNumber + ':' + passcode)}`}
        }).promise
            .then((result: LoginResult) => {
                if (result.tokenExpiration) {
                    this.expirationTime = Date.parse(result.tokenExpiration);
                    this.docNumber = result.docNumber;
                }
                onSuccess(this.expirationTime, result.status);
            })
            .catch((result: any) => {
                const error = result as ErrorResult;
                if (error && error.error) {
                    onFail(`Status: ${error.status} Error: ${error.error}}`);
                }
                else if (error.message === "401") {
                    onFail("Login Failed");
                }
                else {
                    onFail(`Unable to connect to the server.`);
                }
            });
    }

    logout = () => {
        fetch( Utilities.apiUrl + "/logout")
            .then(() => {
                this.expirationTime = 0;
                this.docNumber = undefined;
                Object.values(AppCookies).forEach(Utilities.deleteCookie);
                if (window.location.pathname === "/logout") {
                    window.location.pathname = '/';
                }
                else {
                    window.location.reload();
                }
            })
            .catch(e => {
                console.log("unable to logout", e);
            });
    }
}