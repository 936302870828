import React, {FormEvent} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Session from "../Utilities/Session";
import TextInput from "../Inputs/TextInput";
import TokenTimer from "../Modals/TokenTimer";
import AppBanner from "./AppBanner";
import ErrorModal from "../Modals/ErrorModal";
import Alert from "react-bootstrap/Alert";
import {Link} from "react-router-dom";
import Faqs from "./Faqs";
import Utilities from "../Utilities/Utilities";

interface LoginPageProps {
    onLoginSuccess: (docNum: string) => void;
}

const LoginPage: React.FunctionComponent<LoginPageProps> = ({children, onLoginSuccess}) => {
    const [loading, setLoading] = React.useState(true);
    const [errormessage, setErrorMessage] = React.useState<string>();
    const [authenticated, setAuthenticated] = React.useState(false);
    const [expirationTime, setExpirationTime] = React.useState(-1);
    const [faqsOpen, setFaqsOpen] = React.useState(false)
    const checkIfServerAvailable = () => {
        if(Utilities.getCookie("isAuthenticated") !== undefined) {
            Session.getInstance().isLoggedIn((loggedIn, expTime) => {
                if (loggedIn === true) {
                    setLoading(false);
                    setErrorMessage(undefined);
                    if (expTime) {
                        setExpirationTime(expTime);
                    }
                    setAuthenticated(true);
                    onLoginSuccess(Session.getInstance().docNumber!);
                } else if (loggedIn !== '404') {
                    setLoading(true);
                    setErrorMessage(`Unable to connect to server: ${loggedIn}`);
                    setTimeout(checkIfServerAvailable, 10000);
                } else {
                    setLoading(false);
                }
            });
        } else {
            setLoading(false);
        }
    }
    React.useEffect(checkIfServerAvailable, []);

    const [docNumber, setDocNumber] = React.useState("");
    const [passcode, setPasscode] = React.useState("");
    const [validated, setValidated] = React.useState(false);
    const [partialSuccessMessage, setPartialSuccessMessage] = React.useState<string>();

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (event.currentTarget.checkValidity()) {
            setLoading(true);
            setErrorMessage(undefined);
            Session.getInstance().login(docNumber, passcode,
                (expTime, status) => {
                    if (status === "SUCCESS") {
                        if (expTime) {
                            setExpirationTime(expTime);
                        }
                        Utilities.setCookie("isAuthenticated", "true", expTime);
                        setAuthenticated(true);
                        onLoginSuccess(Session.getInstance().docNumber!);
                    } else {
                        setLoading(false);
                        setPartialSuccessMessage("Your previous changes are still being processed. Please contact the DOC directly to make any changes.");
                        setPasscode("");
                    }
                },
                message => {
                    setLoading(false);
                    setPasscode("");
                    setValidated(false);
                    setErrorMessage(message);
                });
        } else {
            setValidated(true);
        }
    };

    const today = new Date()

    const isHalloweenPeriod = today.getMonth()===9 || (today.getMonth()===10 && today.getDate()<=3)

    return (
        <AppBanner isLoggedIn={authenticated}>
            {authenticated ? (
                <div>
                    {children}
                    <TokenTimer expirationTime={expirationTime}/>
                </div>
            ) : (
                <>
                {isHalloweenPeriod && <Row className="justify-content-center">
                        <Col sm={12} style={{padding: '10px 20px 0 20px'}}>
                            <table style={{border:"2px solid Red"}}>
                                <tbody>
                                <tr><td style={{padding: '5px'}}>
                                    <b>Halloween Message:</b> Registered sex offenders under Active Community Supervision with
                                    the Wisconsin Department of Corrections are prohibited from participating in Halloween activities.
                                    If you have questions and are under Active Community Supervision, please contact your agent of record
                                    for additional guidance. Registered sex offenders no longer on state supervision are not subject to
                                    this DOC restriction but may want to check local ordinances for possible restrictions.
                                </td></tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>}
                    <Row className="justify-content-center">
                        <Col sm={6} md={4} xl={3}>
                            <Form onSubmit={handleSubmit} noValidate validated={validated}
                                  className="mt-md-3 mb-md-3">
                                <Card>
                                    <Card.Header>Use your DOC # and the Passcode shown on your Registration
                                        Letter</Card.Header>
                                    <Card.Body>
                                        <Form.Row className="justify-content-center">
                                            <Col md={8}>
                                                {loading ?
                                                    <Row className="justify-content-center">
                                                        <Spinner animation="border" role="status"/>
                                                    </Row>
                                                    :
                                                    <div>
                                                        <Form.Group>
                                                            <TextInput
                                                                prompt="DOC Number"
                                                                placeholder=""
                                                                value={docNumber}
                                                                onChange={setDocNumber}
                                                                errormessage="You must enter your DOC #."
                                                                id="docNumber"
                                                                type='username'
                                                                maxLength={50}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <TextInput
                                                                prompt="Passcode"
                                                                placeholder=""
                                                                onChange={setPasscode}
                                                                value={passcode}
                                                                errormessage="Please enter a passcode."
                                                                id="passcode"
                                                                type='password'
                                                                maxLength={100}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                }
                                            </Col>
                                        </Form.Row>
                                        <Alert
                                            variant='danger'
                                            onClose={() => setErrorMessage(undefined)}
                                            show={errormessage !== undefined}
                                            dismissible
                                        >
                                            {errormessage}
                                        </Alert>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button
                                            type="submit"
                                            onSubmit={handleSubmit}
                                            block
                                        >Login</Button></Card.Footer>
                                </Card>
                            </Form>
                        </Col>
                        <ErrorModal
                            error={partialSuccessMessage}
                            onClose={() => setPartialSuccessMessage(undefined)}
                        />
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Button variant={"link"} onClick={() => setFaqsOpen(true)}>FAQs</Button>
                            <Faqs open={faqsOpen} onClose={() => setFaqsOpen(false)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Link to='/about'>About</Link>
                        </Col>
                    </Row>
                </>
            )}
        </AppBanner>
    );
}
export default LoginPage;