import React from "react";
import {SectionNextButtonHandler} from "./SectionBase";
import ListSectionBase, {ListSectionStringsFromItemName} from "./ListSectionBase";
import PhoneDisplay from "../DisplayOnly/PhoneDisplay";
import PhoneInput from "../Inputs/PhoneInput";
import {LookupValue, OffenderPhone, Phone} from "../generated";

const PhoneSection: React.FunctionComponent<SectionNextButtonHandler> = ({onButtonClick}) => {
    const [invalidType, setInvalidType] = React.useState<LookupValue>()
    return (
        <ListSectionBase<Phone, OffenderPhone>
            apiUrl='/phone'
            strings={ListSectionStringsFromItemName("Phone Number")}
            onItemSubmit={onButtonClick}
            displayItem={(item) => <PhoneDisplay isTypeInvalid={item.type?.id === invalidType?.id} {...item}/>}
            displayItemInput={(item, updateItem) => (
                <PhoneInput
                    phone={item}
                    updateItem={updateItem}
                    isTypeInvalid={item.type?.id === invalidType?.id}
                />
            )}
            onOldItemCurrentChange={(current, updateItem) => updateItem({current})}
            customValidator={phones => {
                let types = {} as Record<string, string>;
                setInvalidType(undefined);
                for (let phone of phones) {
                    if (phone.type) {
                        if (types[phone.type.value!]) {
                            setInvalidType(phone.type)
                            return `You can only have one phone of type: ${phone.type.value}`;
                        }
                        types[phone.type.value!] = phone.type.value!;
                    }
                }
                return undefined;
            }}
            maxItems={3}
        />
    );
}
export default PhoneSection;