import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import LoginPage from "./LoginPage";
import {BrowserRouter, Route} from "react-router-dom";
import Switch from "react-bootstrap/Switch";
import LogoutPage from "./LogoutPage";
import UpdateInformationPage from "../Pages/UpdateInformationPage";
import AboutPage from "../Pages/AboutPage";
import Faqs from "./Faqs";

export interface AppRouterLink {
    path: string;
    displayName: string;
    component: React.ComponentType<any>;
}
const defaultLogoutRoute: AppRouterLink = {
    path: '/logout',
    displayName: 'Log Out',
    component: LogoutPage

};
interface AppRouterProps {
    logoutPage?: AppRouterLink;
}
const AppRouter: React.FunctionComponent<AppRouterProps> = (
    {
        children,
        logoutPage = defaultLogoutRoute
    }) => {

    const [docNumber, setDocNumber] = React.useState("");
    const [faqsOpen, setFaqsOpen] = React.useState(false);

    return (
        <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE || ''}>
            <Faqs open={faqsOpen} onClose={() => setFaqsOpen(false)} />
            <Switch style={{paddingLeft: 0}}>
                <Route exact path='/'>
                    <LoginPage onLoginSuccess={setDocNumber}>
                        <Navbar variant="dark" expand="sm" className="nav-header">
                            <Nav className="mr-auto">
                                <Nav.Item>DOC #{docNumber}</Nav.Item>
                            </Nav>
                            <Nav>
                                <Nav.Item>
                                    <Nav.Link href="#" onClick={() => {
                                        setFaqsOpen(true)
                                    }}>
                                        FAQs
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Nav>
                                <Nav.Item>
                                    <Nav.Link href={logoutPage.path}>{logoutPage.displayName}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Navbar>
                        <UpdateInformationPage />
                    </LoginPage>
                </Route>
                <Route {...logoutPage} />
                <Route path='/about' component={AboutPage}/>
                {children}
            </Switch>
        </BrowserRouter>
);
}

export default AppRouter;