import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface HelpModalProps {
    show?: boolean;
    title?: string;
}
const HelpModal: React.FunctionComponent<HelpModalProps> = ({children, show, title}) => {
    const [visible, setVisible] = React.useState<boolean>();

    React.useEffect(() => {
        setVisible(show);
    }, [show]);

    const handleClose = () => {
        setVisible(false);
    }
    return (
        <Modal show={visible} onHide={handleClose} centered>
            {title &&
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            }
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
export default HelpModal;