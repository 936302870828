import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {ButtonVariant} from "react-bootstrap/types";

export interface ErrorModalButtonInfo {
    text: string;
    variant?: ButtonVariant;
    onClick: () => void;
}

interface ErrorModalProps {
    title: string;
    error: any | null;
    additionalButtons?: ErrorModalButtonInfo[];
    reloadCallback?: () => void;
    onClose?: () => void;
}

interface ErrorModalState {
    message: string | null;
    show: boolean;
}
export default class ErrorModal extends React.Component<ErrorModalProps, ErrorModalState> {
    static defaultProps = {
        title: `Error`,
        error: null
    }

    getMessage = (error: any| null): string|null => {
        if (!error) {
            return null;
        }
        if (typeof error === 'string') {
            return error;
        }
        if (typeof error === 'number') {
            return `${error}`;
        }
        if ('error' in error) {
            return error.error;
        }
        return `${error}`;
    }

    constructor(props: ErrorModalProps) {
        super(props);
        let message = this.getMessage(props.error);
        let show = false;
        if (message !== null) {
            show = true;
        }
        this.state = {message, show};
    }
    handleClose = () => {
        this.setState({show: false, message: null});
        this.props.onClose?.();
    }
    handleButtonClick = (onClick: ()=> void) => () => {
        this.setState({show: false, message: null});
        this.props.onClose?.();
        onClick();
    }
    componentDidUpdate(prevProps: Readonly<ErrorModalProps>, prevState: Readonly<ErrorModalState>, snapshot?: any) {
        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({show: true, message: this.getMessage(this.props.error)});
        }
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={this.handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.message}</Modal.Body>
                <Modal.Footer>
                    {this.props.additionalButtons?.map((button, i) =>
                        <Button
                            key={i}
                            variant={button.variant}
                            onClick={this.handleButtonClick(button.onClick)}
                        >
                            {button.text}
                        </Button>
                    )}
                    {this.props.reloadCallback &&
                    <Button
                        variant='secondary'
                        onClick={this.handleButtonClick(this.props.reloadCallback)}
                    >
                        Retry
                    </Button>}
                    <Button variant="primary" onClick={this.handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}