import {Country, LookupValue, USState} from "../generated";

/********
 * Cities
 */
export const OutOfStateCity = {id: 117, value: 'Out Of State'};
export const OtherCity = {id: 121, value: 'Other'};
//City Helpers
export const isCityOther = (city?: LookupValue): boolean => city?.value === OtherCity.value;
export const isCityOutOfState = (city?: LookupValue): boolean => city?.value === OutOfStateCity.value;


/********
 * Counties
 */
export const OutOfStateCounty = {id: 114, value: 'Out Of State'};
export const FederalCounty = {id: 113, value: "Federal"};
export const OutOfCountryCountry = {id: 526, value: "Outside of United States"};
export const OutOfCountryCountryNo = {id: 527, value: "Outside of United States -- No "};
//County helper
export const isCountyOutOfState = (county?: LookupValue): boolean => county?.id === OutOfStateCounty.id;
export const isCountyOutOfCountry = (county?: LookupValue): boolean => {
    switch (county?.id ?? 0) {
        case FederalCounty.id:
        case OutOfCountryCountry.id:
        case OutOfCountryCountryNo.id:
            return true;
        default:
            return false;
    }
}

/********
 * States
 */
export const Wisconsin = {id: 54, value: 'WI', abbreviation: 'WI', name: 'Wisconsin'};
export const OutOfCountryState = {id: 57, name: "Out of Country", value: "OOC", abbreviation: "OOC"};
export const ArmedForcesEuropeState = {
    id: 58,
    name: "Armed Forces - Europe, Middle East, Africa or Canada",
    value: "AE",
    abbreviation: "AE"
};
export const ArmedForcesPacificState = {id: 59, name: "Armed Forces - Pacific", value: "AP", abbreviation: "AP"};
export const ArmedForcesAmericaState = {
    id: 60,
    name: "Armed Forces - Americas excluding Canada",
    value: "AA",
    abbreviation: "AA"
};
//State helpers
export const isStateWisconsin = (state?: USState): boolean => state?.id === Wisconsin.id;
export const isStateOutOfCountry = (state?: USState): boolean => {
    switch (state?.id ?? 0) {
        case OutOfCountryState.id:
        case ArmedForcesEuropeState.id:
        case ArmedForcesPacificState.id:
        case ArmedForcesAmericaState.id:
            return true;
        default:
            return false;
    }
}

/********
 * Countries
 */
export const UnitedStates = {id: 17, value: "US", description: "UNITED STATES OF AMERICA (USA)"};
//Country helpers
export const isUnitedStates = (country?: Country): boolean => country?.id === UnitedStates.id;
