import React,{createContext, useContext} from "react"

type EntityValue = {
    name : string
}

const EntityContext = createContext<EntityValue>({ name: ""});

type Props = {
    entityName: string
};

export function EntityProvider(props:React.PropsWithChildren<Props>){
    const {children} = props;

    return <EntityContext.Provider value={{ name: props.entityName}}>{children}</EntityContext.Provider>
}

export function useEntityContext() : EntityValue{
    const context = useContext(EntityContext);
    if(context)
    {
        return context;
    }
    return {name: ""};
}