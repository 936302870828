import React from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import {ListGroup} from "react-bootstrap";

const AboutPage:React.FunctionComponent<any> = () => {
    const unravel = (data: any, prevKey: string = ""): JSX.Element[] => {
        if (typeof data === 'object') {
            return Object.keys(data).sort().filter(key => !/[A-Z]/.test(key))
                .flatMap((key) =>
                    unravel(data[key], prevKey ? `${prevKey}.${key}` : key));
        } else {
            if(prevKey === "git_tag") {
                return [(
                    <ListGroup.Item key={prevKey}>{`${prevKey}: ${data}`}</ListGroup.Item>
                )];
            }
            return []
        }
    }

    return (
        <Container>
            <h4 className="text-center">
                Developed by the Bureau of Technology Management
                <br/>
                for the Wisconsin Department of Corrections
            </h4>
            <Alert variant="danger">
                Warning: Use of this application is restricted to authorized users only! Any unauthorized use is
                strictly prohibited and may result in severe civil and criminal penalties.
            </Alert>
            <ListGroup>
                <ListGroup.Item active>Client Properties</ListGroup.Item>
                {unravel((window as any)._env_)}
                {/*<ListGroup.Item active>Web Service Properties</ListGroup.Item>
                <JsonLoader url="/actuator/info" onLoad={setInfo}>
                    {unravel(info)}
                </JsonLoader>*/}
            </ListGroup>
        </Container>
    );
}
export default AboutPage;