import React, {Component, FormEvent} from "react";
import Form from "react-bootstrap/Form";
import StatePicker from "../Inputs/StatePicker";
import TextInput from "../Inputs/TextInput";
import Container from "react-bootstrap/Container";
import Utilities, {CancelablePromise} from "../Utilities/Utilities";
import PreviousNextButtons from "../Inputs/PreviousNextButtons";
import ErrorModal from "../Modals/ErrorModal";
import HeightInput from "../Inputs/HeightInput";
import WeightInput from "../Inputs/WeightInput";
import Button from "react-bootstrap/Button";
import ColorPicker from "../Inputs/ColorPicker";
import Card from "react-bootstrap/Card";
import LoadingContainer from "../Utilities/LoadingContainer";
import {SectionNextButtonHandler} from "./SectionBase";
import {Identification, IdentificationResponse} from "../generated";
import DateDisplay from "../DisplayOnly/DateDisplay";


interface IdentificationInformationState extends IdentificationResponse {
    loading: boolean;
    error?: any;
    validated: boolean;
    retryFunction?: () => void;
}

export default class IdentificationInformation extends Component<SectionNextButtonHandler, IdentificationInformationState> {

    state = {
        loading: true,
        validated: false
    } as IdentificationInformationState;

    request?: CancelablePromise<any> = undefined;
    componentWillUnmount() {
        this.request?.cancel();
    }
    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({loading: true});
        const apiUrl = "/identification";
        this.request = Utilities.fetchJSON<IdentificationResponse>({url: apiUrl});
        this.request.promise.then(identification => {
            if (identification) {
                this.setState(identification);
            }
            this.setState({loading: false});
            this.request = undefined;
        }).catch((error: any) => {
            if (!error.isCanceled) {
                this.setState({
                    error,
                    retryFunction: this.getData,
                    loading: false
                });
                this.request = undefined;
            }
        });
    }

    handleSubmitForm = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.currentTarget.checkValidity()) {
            this.handleSubmit();
        }
        else {
            this.setState({validated: true});
        }
    }

    handleSubmit = () => {
        if (this.formRef?.current?.checkValidity()) {
            this.setState({loading: true});
            const apiUrl = "/identification";
            this.request = Utilities.fetchJSON<Identification>({
                url: apiUrl,
                body: JSON.stringify(this.state.identification)
            });
            this.request.promise.then(() => {
                this.props.onButtonClick(1);
                this.request = undefined;
            }).catch((error: any) => {
                if (!error.isCanceled) {
                    this.setState({
                        loading: false,
                        error,
                        retryFunction: this.handleSubmit
                    });
                    this.request = undefined;
                }
            });
        }
        else {
            this.setState({validated: true});
        }
    }
    setIdentification = (id: Partial<Identification>) => {
        this.setState((prevState) => {
            return {identification: {...prevState.identification, ...id}};
        });
    }

    formRef = React.createRef<HTMLFormElement>();
    render() {
        let name = "";
        if (this.state.namesDetail) {
            name = Utilities.parseName(this.state.namesDetail);
        }
        return (
            <Container className="mt-4">
                <LoadingContainer loading={this.state.loading}>
                    <Form ref={this.formRef} noValidate validated={this.state.validated} onSubmit={this.handleSubmitForm}>
                        <Card>
                            <Card.Body>
                                <Card.Subtitle><span className="text-muted">Name:</span> {name}</Card.Subtitle>
                                <Card.Text><span className="text-muted">Date Of Birth:</span> <DateDisplay value={this.state.dob} /></Card.Text>
                            </Card.Body>
                            <Card.Footer className="text-muted">
                                **To change any of the information noted above (Name, DOB), you will need to contact DOC directly at 608-240-5830.
                            </Card.Footer>
                        </Card>
                        <br/>
                        <h4>Identification Information</h4>
                        <Form.Row>
                            <HeightInput
                                colProps={{sm:3}}
                                prompt="Height"
                                value={this.state.identification?.height}
                                onChange={(height) =>
                                    this.setIdentification({height})}
                            />
                            <WeightInput
                                colProps={{sm:3}}
                                value={this.state.identification?.weight}
                                onChange={weight => this.setIdentification({weight})}
                            />
                            <ColorPicker
                                colProps={{xs: 6, sm: 3}}
                                value={this.state.identification?.eyeColor}
                                onChange={(eyeColor?: string) => this.setIdentification({eyeColor})}
                                prompt="Eye Color"
                                errormessage="You must select an eye color."
                                id="eyeColor"
                                apiUrl='/lookup/eyecolor'
                            />
                            <ColorPicker
                                colProps={{xs: 6, sm: 3}}
                                prompt="Hair Color"
                                value={this.state.identification?.hairColor}
                                onChange={(hairColor?: string) => this.setIdentification({hairColor})}
                                errormessage="You must select a hair color."
                                id="hairColor"
                                apiUrl='/lookup/haircolor'
                            />
                        </Form.Row>
                        <Form.Row>
                            <TextInput
                                colProps={{sm: 6}}
                                prompt="Driver License or Identification Card"
                                placeholder="Identification Card #"
                                value={this.state.identification?.driversLicense}
                                onChange={driversLicense => this.setIdentification({driversLicense})}
                                id="idNum"
                                maxLength={50}
                            />
                            <StatePicker
                                colProps={{sm: 6}}
                                prompt="State Issued"
                                value={this.state.identification?.stateIssued}
                                onChange={(stateIssued?: string) => this.setIdentification({stateIssued})}
                                errormessage={(this.state.identification?.driversLicense ?? "").length > 0 ? "You must select a state." : undefined}
                            />
                        </Form.Row>
                        <Button type="submit" style={{display: "none"}}/>
                        <PreviousNextButtons
                            onButtonClick={this.handleSubmit}
                            allowPreviousButton={false}
                        />
                    </Form>
                </LoadingContainer>
                <ErrorModal error={this.state.error} reloadCallback={() => {
                    this.setState({error: undefined});
                    this.state.retryFunction?.();
                }}/>
            </Container>
        );
    }
}