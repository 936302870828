import React from "react";
import Form from "react-bootstrap/Form";
import TextInput from "../Inputs/TextInput";
import {SectionNextButtonHandler} from "./SectionBase";
import {InternetUsageDisplay} from "../DisplayOnly/InternetUsageDisplay";
import AddOnlyListSection from "./AddOnlyListSection";
import {InternetUsage} from "../generated";
import {ColProps} from "react-bootstrap/Col";

const InternetSection: React.FunctionComponent<SectionNextButtonHandler> = ({onButtonClick}) => {
    const colProps:ColProps = {xs: 'auto', md: 6};
    return (
        <AddOnlyListSection<InternetUsage>
            url='/internet'
            prompt="List all websites you register to use including social networking sites and provide your login, user, or profile name for each. This requirement only applies to an account, internet address, or internet profile you create, use, or maintain for your personal, family, or household. Therefore you do not need to report every website you visit or browse. Do not provide passwords."
            readonlyHeader="Registered Websites and Usernames"
            addNewItemPrompt="Add Website and Username"
            displayReadonlyItem={InternetUsageDisplay}
            displayItem={(usage, updateItem) =>
                <Form.Group>
                    <Form.Row>
                        <TextInput
                            prompt="Website"
                            placeholder="---"
                            value={usage.website}
                            onChange={website => updateItem({website})}
                            id="website"
                            errormessage="You must enter a website."
                            colProps={colProps}
                            maxLength={50}
                        />
                        <TextInput
                            prompt="Email address or username"
                            placeholder="---"
                            value={usage.alias}
                            onChange={alias => updateItem({alias})}
                            id="alias"
                            errormessage="You must enter a username."
                            colProps={colProps}
                            maxLength={50}
                        />
                    </Form.Row>
                </Form.Group>
            }
            onButtonClick={onButtonClick}
        />
    );
}
export default InternetSection;
