import React from "react";
import {SectionNextButtonHandler} from "./SectionBase";
import {Employer, OffenderEmployer} from "../generated";
import {ListSectionStringsFromItemName} from "./ListSectionBase";
import EmployerDisplay from "../DisplayOnly/EmployerDisplay";
import BaseEndDateSection from "./BaseEndDateSection";
import EmployerInput from "../Inputs/EmployerInput";
import {EntityProvider} from "../Providers/EntityProvider";


const EmployerSection: React.FunctionComponent<SectionNextButtonHandler> = ({onButtonClick}) => {
    return (
        <EntityProvider entityName="employer">
        <BaseEndDateSection<Employer, OffenderEmployer>
            strings={ListSectionStringsFromItemName("Employer")}
            apiUrl='/employer'
            onItemSubmit={onButtonClick}
            displayItemInput={(employer, updateItem) => <EmployerInput {...{employer, updateItem}}/>}
            displayItem={EmployerDisplay}
            endDatePromptTitle="When did your employment end?"
        />
        </EntityProvider>
    );
}

export default EmployerSection;