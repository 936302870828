import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css"
import AppRouter from "./AppRouter";
import React from 'react';

const App:React.FC = () => {
    return (
        <AppRouter />
    );
}

export default App;
