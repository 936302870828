import React from "react";
import InputLabelAndFeedback, {InputLabelAndFeedbackProps} from "./InputLabelAndFeedback";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";

export interface DatePickerProps extends Omit<InputLabelAndFeedbackProps, 'id'> {
    prompt: string;
    value?: string;
    onChange: (date?: string) => void;
    errormessage?: string;
    id?: string;
    startDate?: string;
}


const DatePickerWrapper: React.FunctionComponent<DatePickerProps> = (
    {
        colProps = {
            xs: 8,
            sm: 5,
            md: 6,
            lg: 4
        },
        id = "datepicker",
        prompt,
        value,
        onChange,
        errormessage,
        startDate = null
    }) => {

    const parseDate = (val: string) => {
        const d = new Date(val);
        const offset = d.getTimezoneOffset() * 60000;
        return new Date(d.getTime() + offset);
    }

    const date = value ? parseDate(value) : null;

    const handleChange = (val: Date | null) => {
        onChange(val?.toISOString().split('T')[0]);
    }

    return (
        <InputLabelAndFeedback
            prompt={prompt}
            colProps={colProps}
            errormessage={errormessage}
            id={id}
        >
            <DatePicker
                selected={date}
                required={errormessage !== undefined}
                onChange={handleChange}
                customInput={<Form.Control value={value}/>}
                minDate={startDate ? parseDate(startDate) : null}
                ariaLabelledBy={id}
            />
        </InputLabelAndFeedback>
    );
}
export default DatePickerWrapper;