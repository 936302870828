import React from "react";
import {ColProps, InputGroup} from "react-bootstrap";
import InputLabelAndFeedback from "./InputLabelAndFeedback";
import {ManagedInput} from "./ManagedInput";
import Form from "react-bootstrap/Form";

interface WeightInputProps {
    colProps?: ColProps;
    value?: number;
    onChange(value: number): void;
}
interface WeightInputState {
}
export default class WeightInput extends React.Component<WeightInputProps, WeightInputState> {
    render() {
        return (
            <InputLabelAndFeedback
                colProps={this.props.colProps}
                prompt="Weight"
                errormessage="You must enter a weight."
                id="weight"
            >
                <InputGroup>
                    <ManagedInput
                        placeholder="---"
                        onChange={v => this.props.onChange(+v)}
                        value={this.props.value ? `${this.props.value}` : ''}
                        regexp={/^[0-9]+$/}
                        maxLength={3}
                        minLength={2}
                        required
                        labelledby="weight"
                        type='number'
                    />
                    <InputGroup.Append>
                        <InputGroup.Text>lbs</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">You must enter a weight.</Form.Control.Feedback>
                </InputGroup>
            </InputLabelAndFeedback>
        );
    }
}