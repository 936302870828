import React from "react";
import {SectionNextButtonHandler} from "./SectionBase";
import {EmergencyContact, LookupValue, OffenderCollateral} from "../generated";
import {EmergencyContactDisplay} from "../DisplayOnly/EmergencyContactDisplay";
import ListSectionBase, {SingleUpdateSectionStringsFromItemName} from "./ListSectionBase";
import EmergencyContactInput from "../Inputs/EmergencyContactInput";
import Utilities from "../Utilities/Utilities";


const EmergencyContactsSection: React.FunctionComponent<SectionNextButtonHandler>  = ({onButtonClick}) => {

    const [suffixes, setSuffixes] = React.useState<LookupValue[]>([]);

    React.useEffect(() => {
        const request = Utilities.fetchJSON<LookupValue[]>({url: `/lookup?lookupType=Suffix`, useCache: true});
        request.promise.then(setSuffixes).catch(error => console.log(error));

        return () => {
            request.cancel();
        }
    }, []);

    return (
        <ListSectionBase<EmergencyContact, OffenderCollateral>
            strings={SingleUpdateSectionStringsFromItemName('Emergency Contact')}
            apiUrl='/emergencycontact'
            onItemSubmit={onButtonClick}
            displayItem={EmergencyContactDisplay}
            displayItemInput={(item, updateItem) => (
                    <EmergencyContactInput contact={item} updateItem={updateItem} nameSuffixes={suffixes}/>
                )
            }
            onOldItemCurrentChange={(current, updateItem) => updateItem({current})}
        >
        </ListSectionBase>
    );
}
export default EmergencyContactsSection;